import { shopConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: [],
};

function getOneProduct(state = initialState, action) {
    switch (action.type) {
        case shopConstants.GET_ONE_PRODUCT_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.GET_ONE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        default:
            return state
    }
}

export default getOneProduct;