import React from 'react';
import '../../css/settings.css'

export const AccountDetails = (props) => {
    const photo = props.profile_photo;
    const url = 'http://api.test.getripay.com';  //test
    //const url = 'https://api.getripay.com';  //live
    const completeUrl = url + photo;
 
    return (
        <>
            <h3>Account Details</h3>
            <div className='account-details-box'>
                <div style={{textAlign:'center'}}>
                <img src={completeUrl} alt='profile' />
                </div>
              
                <p> Name-  {props.first_name} {props.last_name}</p>
                {/* <p>Account Number: {props.user && props.user.bank_account ? user.bank_account[0].account_number : ''}</p> */}
                <p> Address:  {props.address}</p>
                <p> Email:  {props.email}</p>
                <p> Bvn:  {props.bvn}</p>
                <p>Gender: {props.gender}</p>
                <p>Marital Status: {props.marital_status}</p>
                <p>Date of birth: {props.date_of_birth}</p>
            </div>
        </>

    )
}
