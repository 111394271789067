import React, { useEffect } from "react";
import Navigation from "../../components/Navigation";
import { Row, Col, Tabs } from "antd";
import { connect, useSelector } from "react-redux";
import store from "../../services/storeService";
import "../../css/overview.css";
import { HeaderBar } from "../../Apperance";
import report from "../../services/reportService";
import { TopSellingItems } from "./TopSellingItems";
import { ProductViews } from "./ProductViews";
import { CheckoutViews } from "./CheckoutViews";
import { SalesReport } from "./SalesReport";
import { ProfitLoss } from "./ProfitLoss";
import { CheckoutLocation } from "./CheckoutLocation";

const { TabPane } = Tabs;

const Report = (props) => {
  const user = useSelector((state) => state.user.data);
  const current = useSelector((state) => state.current.data);
  const storeId = props.store ? props.store.id : "";

  useEffect(() => {
    props.get_one_store(current);
  }, []);

  useEffect(() => {
    if (storeId) {
      props.top_items(storeId);
      props.product_views(storeId);
      props.checkout_views(storeId);
      props.sales_report(storeId);
      props.profit_loss(storeId);
      props.checkout_location(storeId);
    }
  }, [storeId]);

  return (
    <Navigation {...props}>
      <HeaderBar topic="Store Report" />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Top Selling Products" key="1">
            <TopSellingItems topItems={props.topItems} />
          </TabPane>
          <TabPane tab="Sales Report" key="2">
            <SalesReport salesReport={props.salesReport} />
          </TabPane>
          <TabPane tab="Profit and Loss" key="3">
            <ProfitLoss profitLoss={props.profitLoss} />
          </TabPane>
          <TabPane tab="Product Views" key="4">
            <ProductViews views={props.productViews} />
          </TabPane>
          <TabPane tab="Checkout Views" key="5">
            <CheckoutViews views={props.checkoutViews} />
          </TabPane>
          <TabPane tab="Checkout Locations" key="6">
            <CheckoutLocation checkoutLocation={props.checkoutLocation} />
          </TabPane>
        </Tabs>
      </div>
    </Navigation>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.getOneStore.data,
    topItems: state.topItems.topItems,
    productViews: state.productViews.productViews,
    checkoutViews: state.checkoutViews.checkoutViews,
    salesReport: state.salesReport.salesReport,
    profitLoss: state.profitLoss.profitLoss,
    checkoutLocation: state.checkoutLocation.checkoutLocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_one_store: (store_id) => dispatch(store.get_one_store(store_id)),
    top_items: (storeId) => dispatch(report.top_items(storeId)),
    product_views: (storeId) => dispatch(report.product_views(storeId)),
    checkout_views: (storeId) => dispatch(report.checkout_views(storeId)),
    sales_report: (storeId) => dispatch(report.sales_report(storeId)),
    profit_loss: (storeId) => dispatch(report.profit_loss(storeId)),
    checkout_location: (storeId) => dispatch(report.checkout_location(storeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
