import { Button, Col, Input, Modal, Row } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../css/invoice.css';
import invoice from '../../services/invoiceService';
//import Pdf from "react-to-pdf";
import html2pdf from 'html2pdf.js'
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";

export const InvoiceDetails = (props) => {

    const ref = createRef();

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const user = useSelector(state => state.user.data);
    const url = 'https://getrishop.lending.ng';

    const [data, setData] = useState({
        invoiceUrl: url + props.match.url,
        receiverEmail: email,
        sender: user.first_name + ' ' + user.last_name
    });

    const invoice_number = props.match.params.slug;
    const oneInvoice = useSelector(state => state.getOneInvoice.data)

    function handleOpen() {
        setShow(true)
    }
    console.log(data)

    function handleClose() {
        setShow(false)
    }

    useEffect(() => {
        dispatch(invoice.get_one_invoice(invoice_number))
    }, [])

    useEffect(() => {
        setData({
            invoiceUrl: url + props.match.url,
            receiverEmail: email,
            sender: user.first_name + ' ' + user.last_name
        })
    }, [email])

    function sendEmail() {
        dispatch(invoice.send_email(data))
    }

    function down() {
        var opt = {
            margin: 1,
            filename: 'myfile.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true, allowTaint: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
        
        // New Promise-based usage:
        html2pdf().set(opt).from(ref.current).save();

    };
    const checkId = localStorage.getItem('user_id')

    return (
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Row style={{ textAlign: 'center', marginBottom: '30px' }}>
                <Col lg={4}></Col>
                {checkId !== null && checkId === oneInvoice.userId
                    ?
                    <Col lg={8}>
                        <Button type='primary' onClick={handleOpen}>Send via Email</Button>
                        <Modal footer={null} title="Send invoice via email" visible={show} onCancel={handleClose} style={{ textAlign: 'center' }}>
                            <img src='/images/accept1.svg' alt='accept-order' width='150' />
                            <p>Please enter the email address of the receiver below</p>
                            <Input placeholder='email address' name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Button type="primary" onClick={sendEmail}>
                                Send
                </Button>
                        </Modal>
                    </Col>
                    :
                    null
                }

                <Col lg={8}> <Button onClick={down}>Download Invoice</Button></Col>
                <Col lg={4}></Col>
            </Row>

            {/* 

            <Pdf targetRef={ref} filename={`${oneInvoice.invoiceNumber}.pdf`}>
                {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
            </Pdf> */}

            <div className="invoice-box" ref={ref}>
                <div >
                    <Row>
                        <Col lg={12}>
                            <p>Pay with QR</p>
                            <img src={oneInvoice.QRImageUrl} alt='qr' width='180' />

                        </Col>
                        <Col lg={12} style={{ textAlign: 'right', fontWeight: 'bolder' }}>
                            Invoice: {oneInvoice.invoiceNumber}<br />
							Created: January 1, 2015<br /> <br />

                            <p>OR pay to PatrickGold Account <br />

                             Account Name: {oneInvoice.accountName}
                                <br />
                             AccountNumber: {oneInvoice.accountNumber}

                            </p>

                        </Col>
                    </Row>

                    <Row style={{ marginBottom: "30px", }}>
                        {oneInvoice.sender && (
                            <Col lg={12} sm={12} xs={12}>
                                <p style={{ fontWeight: "bolder" }}>FROM</p>
                                {oneInvoice.sender.company}.<br />
                                {oneInvoice.sender.address}<br />
                                {oneInvoice.sender.city}, {oneInvoice.sender.country}
                            </Col>
                        )}


                        {oneInvoice.client && (
                            <Col lg={12} lg={12} sm={12} xs={12} style={{ textAlign: 'right' }}>
                                <p style={{ fontWeight: "bolder" }}>TO</p>
                                {oneInvoice.client.company}.<br />
                                {oneInvoice.client.address}<br />
                                {oneInvoice.client.city}, {oneInvoice.client.country}
                            </Col>
                        )}

                    </Row>

                    <table>
                        <tr className="heading">
                            <td>Item</td>
                            <td>Description</td>
                            <td>Qty</td>
                            <td>Price</td>
                        </tr>

                        {oneInvoice.items && oneInvoice.items.map((data, i) => {
                            return (
                                <tr className="item" key={i}>
                                    <td>{data.item}</td>
                                    <td>{data.description}</td>
                                    <td>{data.quantity}</td>
                                    <td>NGN {Number(data.price).toLocaleString()}</td>
                                </tr>

                            )
                        })}

                        <tr className="total">
                            <td></td>
                            <td style={{ textAlign: "right" }}>Total: NGN {Number(oneInvoice.subTotal).toLocaleString()}</td>
                        </tr>
                    </table>
                </div>

                <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '13px', fontWeight: 'bolder' }}>
                    <span>POWERED BY: </span>
                    <img src='https://getripay.lending.ng/images/getripaylogo.png' alt='logo' style={{ width: '10%' }} />
                </div>
            </div>
        </div>

    )
}
