import React, { useEffect, useState } from 'react';
import '../../css/add-product.css'
import { Form, Input, Cascader, Button, Select, Row, Col } from 'antd';
import product_requirements from '../../services/productRequirementService';
import { connect, useSelector } from 'react-redux';
import store from '../../services/storeService';
import product from '../../services/productService';


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};
const { Option } = Select;

function StepOne(props) {
    const [form] = Form.useForm();
    const storeId = props.store ? props.store.id : ''
    const user = useSelector(state => state.user.data);
    const current = useSelector(state => state.current.data)
    const [cate, setCate] = useState([]);
    const [types, setTypes] = useState([])
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (user.id) {
            props.get_one_store(current)
        }
        props.get_categories()
        props.get_types()
    }, [user]);

    const [products, setProducts] = useState({
        storeId: storeId,
        productName: '',
        productDescription: '',
        productPrice: '',
        categoryId: '',
        productSubCategory: '',
        productTypeId: '',
        productTags: '',
        productSlug: '',
        isOffline: false
    });

    useEffect(() => {
        setProducts({
            storeId: storeId,
            productName: products.productName,
            productDescription: products.productDescription,
            productPrice: products.productPrice,
            categoryId: cate && Number(cate.toString()),
            productSubCategory: products.productSubCategory,
            productTypeId: types && Number(types.toString()),
            productTags: tags && tags.toString(),
            productSlug: products.productName && (products.productName).replace(/\s+/g, '-').toLowerCase(),
            isOffline: false
        })
        console.log(products)
    }, [props.store, cate, types, tags])

    function handleChange(e) {
        const { name, value } = e.target;
        setProducts((products) => ({ ...products, [name]: value }));
    }

    const onFinish = () => {
        if (products) {
            props.create_product(products)
        }
    };

    useEffect(() => {
        if (props.product.success === true) {
            props.next(1)
        }
    }, [props])

    const type = props.types.map((type, i) => {
        return {
            label: type.productTypeName,
            value: type.id
        }
    })

    const category = props.categories.map((type, i) => {
        return {
            label: type.categoryName,
            value: type.id
        }
    });

    return (

        <Row>
            <Col span={24} lg={4} sm={24} xs={24}></Col>
            <Col span={24} lg={16} sm={24} xs={24}>
                <div className='create-store-box'>
                    <Form
                        className=''
                        style={{ marginTop: '20px' }}
                        {...formItemLayout}
                        form={form}
                        name="register"
                        scrollToFirstError >
                        <Form.Item rules={[{ required: true, message: 'Please enter your product name' }]}>
                            <Input placeholder='Product Name' name="productName" value={products.productName} onChange={handleChange} />
                        </Form.Item>

                        <Form.Item rules={[{ required: true, message: 'Please enter your prodcut description', whitespace: true }]} >
                            <Input name='productDescription' placeholder='Product Description' value={products.productDescription} onChange={handleChange} />
                        </Form.Item>

                        <Form.Item rules={[{ required: true, message: 'Please enter your prodcut price', whitespace: true }]} >
                            <Input placeholder='Product Price' name="productPrice" value={products.productPrice} onChange={handleChange} />
                        </Form.Item>

                        <Form.Item rules={[{ required: true, message: 'Please choose a prodcuct category' }]} >
                            <Cascader onChange={(val) => setCate(val)} options={category} placeholder='Product Category' />
                        </Form.Item>

                        <Form.Item rules={[{ required: true, message: 'Please enter a sub-category' }]}>
                            <Input placeholder='Sub Category' name="productSubCategory" value={products.productSubCategory} onChange={handleChange} />
                        </Form.Item>

                        <Form.Item name="productTypeId" rules={[{ required: true, message: 'Please choose a prodcuct type' }]} >
                            <Cascader onChange={(val) => setTypes(val)} options={type} placeholder='Product Type' />
                        </Form.Item>

                        <Form.Item rules={[{ required: true, message: 'Please choose product tags', type: 'array' }]} >
                            <Select onChange={(val) => setTags(val)} mode="multiple" placeholder="Please select your prodcut tags"  >
                                <Option value="Trendy">Trendy</Option>
                                <Option value="Tech">Tech</Option>
                                <Option value="Hardware">Hardware</Option>
                                <Option value="Fashion">Fashion</Option>
                            </Select>
                        </Form.Item>


                        <Form.Item label=' '>
                            <Button type="primary" htmlType="submit" onClick={onFinish}>
                                Submit
                    </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
            <Col span={24} lg={4} sm={24} xs={24}></Col>
        </Row>


    )
}

const mapStateToProps = (state) => {
    return {
        types: state.productReq.types,
        categories: state.productReq.categories,
        properties: state.productReq.properties,
        store: state.getOneStore.data,
        product: state.createProduct.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_types: () => dispatch(product_requirements.get_types()),
        get_properties: () => dispatch(product_requirements.get_properties()),
        get_categories: () => dispatch(product_requirements.get_categories()),
        get_one_store: (id) => dispatch(store.get_one_store(id)),
        create_product: (data) => dispatch(product.create_product(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepOne)