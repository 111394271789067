import React, { useState } from 'react';
import Navigation from '../../components/Navigation';
import '../../css/add-product.css'
import { Button, Steps, message, Row, Col } from 'antd';
import StepOne from '../../components/AddProducts./StepOne';
import StepThree from '../../components/AddProducts./StepThree';
import { HeaderBar } from '../../Apperance';
import StepTwo from '../../components/AddProducts./StepTwo';
import product from '../../services/productService';


const { Step } = Steps;

function AddProduct(props) {
    console.log(props)

    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Product Description',
            content: <StepOne current={current} next={next} prev={prev} />,
        },
        {
            title: 'Product Properties',
            content: <StepTwo current={current} next={next} prev={prev}/>,
        },
        {
            title: 'Product Image',
            content: <StepThree />,
        },
    ];

    return (
        <>
            <Navigation {...props}>
                <HeaderBar topic="Add a product" />
                <Row>
                    <Col span={24} lg={12} sm={24} xs={24}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <div className='add-product-page' style={{padding:'30px'}}>
                                <Steps current={current}>
                                    {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <div className="steps-content" style={{marginTop:'30px'}}>{steps[current].content}</div>

                                <div className="steps-action">
                                    {/* {current < steps.length - 1 && (
                                        <div style={{ textAlign: 'center' }}>
                                            <Button type="primary" onClick={() => next()}>
                                                Next
                                </Button>
                                        </div>

                                    )} */}
                                    {/* {current === steps.length - 1 && (
                                        <div style={{ textAlign: 'center' }}>
                                            <Button type="primary" onClick={() => message.success('Product successfully created!!!')}>
                                                Done
                                </Button>
                                        </div>


                                    )} */}
                                    {current > 0 && (
                                        <div style={{ textAlign: 'center' }}>
                                            <Button type='primary' style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                Previous
                                </Button>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col span={24} lg={12} style={{ backgroundColor: '#f6f6f7', height: '100vh' }}>
                        {/* {
                            props.store === true ?
                                <p style={{ margin: 'auto', textAlign: 'center' }}>Store created successfully</p>
                                :
                                ''
                        } */}
                    </Col>
                </Row>

            </Navigation>



        </>
    )
}

export default AddProduct;