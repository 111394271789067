import { shopConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    isLoading2:false,
    data: [],
    all:[]
};

function getStorePaymentConfig(state = initialState, action) {
    switch (action.type) {
        case shopConstants.GET_STORE_PAYMENT_CONFIG_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.GET_STORE_PAYMENT_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        default:
            return state
    }
}

function getAllPaymentConfig(state = initialState, action) {
    switch (action.type) {
        case shopConstants.GET_ALL_PAYMENT_CONFIG_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.GET_ALL_PAYMENT_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                all: action.payload,
            };
        default:
            return state
    }
}

function updateStorePaymentConfig(state = initialState, action) {
    switch (action.type) {
        case shopConstants.UPDATE_STORE_PAYMENT_CONFIG_REQUEST:
            return {
                ...state,
                isLoading2: true,

            };
        case shopConstants.UPDATE_STORE_PAYMENT_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading2: false,
            };
        default:
            return state
    }
}

export {
    getStorePaymentConfig, 
    getAllPaymentConfig,
    updateStorePaymentConfig
}