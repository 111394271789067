import React, { useEffect, useState } from 'react';
import { Modal, Input, Row, Col, Button } from 'antd';
import { IKContext, IKUpload } from 'imagekitio-react';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../services/storeService';

const publicKey = 'public_sYCPM1kMSIovPWZMi7Mj83OMCBA=';
const authenticationEndpoint = 'https://api.test.getripay.com/api/v1/ecommerce/auth_params';
const urlEndpoint = 'https://ik.imagekit.io/rnh8t89owod';


export const EditStoreModal = (props) => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const loading = useSelector(state => state.editStore.isLoading);

    const [data, setData] = useState({
        storeName: props.details.storeName,
        storeImageUrl: url === '' ? props.details.storeImageUrl : url,
        preferredUrl: props.details.preferredUrl,
        isOffline: false
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setData((data) => ({ ...data, [name]: value }));
    }

    const onError = err => {
        console.log("Error", err);
    };

    const onSuccess = res => {
        setUrl(res.url)
    };

    useEffect(() => {
        setData({
            storeName: props.details.storeName,
            storeImageUrl: url === '' ? props.details.storeImageUrl : url,
            preferredUrl: props.details.preferredUrl,
            isOffline: false
        })
    }, [url, props.details])

    function submit() {
        dispatch(store.edit_store(props.details.id, data))
    }

    return (
        <Modal footer={null} title={`Edit ${props.details.storeName}`} visible={props.show} onCancel={props.cancel} style={{ textAlign: 'center' }}>
            <img src='/images/edit.svg' alt='edit-store' width='150' />
            <div>
                <Input placeholder='Change store name' name='storeName' value={data.storeName} onChange={handleChange} />
            </div>

            <div>
                <Input placeholder='Change preffered url' name='preferredUrl' value={data.preferredUrl} onChange={handleChange} />
            </div>

            <IKContext
                urlEndpoint={urlEndpoint}
                publicKey={publicKey}
                authenticationEndpoint={authenticationEndpoint}
            >
                <p style={{ textAlign: 'left', marginBottom: '0px', marginTop: '5px' }}>Change store logo</p>
                <IKUpload
                    fileName="ecommerce.png"
                    onError={onError}
                    onSuccess={onSuccess}
                />
            </IKContext>

            <Row style={{ marginTop: '10px' }}>
                <Col lg={12} sm={12} xs={12}>
                    <Button onClick={props.cancel}>Cancel</Button>
                </Col>
                <Col lg={12} sm={12} xs={12}>
                    {loading ?
                        <Button disabled loading>SUBMIT</Button>
                        :
                        <Button onClick={submit}>SUBMIT</Button>
                    }

                </Col>
            </Row>

        </Modal>
    )
}
