import React from "react";
import { Table } from "ant-table-extensions";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyDEG-O15XN4IzN1EzLNQZReXXaLEfkerK4");

export const CheckoutLocation = (props) => {
  const formatAddress = (address) => {
      let ss
    let add1 = address[0];
    let add2 = address[1];
    Geocode.fromLatLng(add1, add2).then(
      (response) => {
        ss = response.results[0].formatted_address;
        console.log(ss);
      },
      (error) => {
        console.error(error);
      }
    );
    return ss
  };

  const locationColumns = [
    {
      dataIndex: "name",
      key: "name",
      title: "Product Name",
      responsive: ["sm"],
    },
    {
      dataIndex: "location",
      key: "location",
      title: "Location",
      responsive: ["sm"],
    },
    {
      dataIndex: "address",
      key: "address",
      render: (value, record) => (
        <p className="text-primary">{formatAddress(JSON.parse(value))}</p>
      ),
      title: "Address",
      responsive: ["sm"],
    },
  ];
  //   const dataSource =
  //     props.checkoutLocation &&
  //     props.checkoutLocation.map((pro, i) => {
  //       return {
  //         key: i,
  //         name: pro.product,
  //         location: pro.location,
  //       };
  //     });

  const dataSource = [
    {
      name: "Plantain",
      location: "[39.807222,-76.984722]",
      address: "[39.807222,-76.984722]",
    },
  ];

  return (
    <div>
      <Table
        scroll={{ x: 1400, y: 600 }}
        dataSource={dataSource}
        columns={locationColumns}
        searchable
        // onChange={onChange}
        sticky
      />
    </div>
  );
};
