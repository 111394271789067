export const shopConstants = {
    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    CURRENT_STORE: 'CURRENT_STORE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    GET_ACCOUNT_BALANCE_REQUEST: 'GET_ACCOUNT_BALANCE_REQUEST',
    GET_ACCOUNT_BALANCE_SUCCESS: 'GET_ACCOUNT_BALANCE_SUCCESS',

    CREATE_STORE_REQUEST: 'CREATE_STORE_REQUEST',
    CREATE_STORE_SUCCESS: 'CREATE_STORE_SUCCESS',

    EDIT_STORE_REQUEST: 'EDIT_STORE_REQUEST',
    EDIT_STORE_SUCCESS: 'EDIT_STORE_SUCCESS',

    GET_STORE_REQUEST: 'GET_STORE_REQUEST',
    GET_STORE_SUCCESS: 'GET_STORE_SUCCESS',

    GET_ONE_STORE_REQUEST: 'GET_ONE_STORE_REQUEST',
    GET_ONE_STORE_SUCCESS: 'GET_ONE_STORE_SUCCESS',

    GET_INDUSTRIES_REQUEST: 'GET_INDUSTRIES_REQUEST',
    GET_INDUSTRIES_SUCCESS: 'GET_INDUSTRIES_SUCCESS',

    PRODUCT_REQ_REQUEST: 'PRODUCT_REQ_REQUEST',
    PRODUCT_CATEGORIES_SUCCESS: 'PRODUCT_CATEGORIES_SUCCESS',
    PRODUCT_TYPES_SUCCESS: 'PRODUCT_TYPES_SUCCESS',
    PRODUCT_PROPERTIES_SUCCESS: 'PRODUCT_PROPERTIES_SUCCESS',

    CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
    CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESSS',

    UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
    UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESSS',

    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESSS',
    GET_ONE_PRODUCT_REQUEST: 'GET_ONE_PRODUCT_REQUEST',
    GET_ONE_PRODUCT_SUCCESS: 'GET_ONE_PRODUCT_SUCCESS',

    CREATE_SUPPLIER_REQUEST: 'CREATE_SUPPLIER_REQUEST',
    CREATE_SUPPLIER_SUCCESS: 'CREATE_SUPPLIER_SUCCESS',

    GET_SUPPLIER_REQUEST: 'GET_SUPPLIER_REQUEST',
    GET_SUPPLIER_SUCCESS: 'GET_SUPPLIER_SUCCESS',

    SUBMIT_SUPPLIES_REQUEST: 'SUBMIT_SUPPLIES_REQUEST',
    SUBMIT_SUPPLIES_SUCCESS: 'SUBMIT_SUPPLIES_SUCCESS',

    GET_SUPPLIES_REQUEST: 'GET_SUPPLIES_REQUEST',
    GET_SUPPLIES_SUCCESS: 'GET_SUPPLIES_SUCCESS',

    GET_WEBSITE_REQUEST: 'GET_WEBSITE_REQUEST',
    GET_WEBSITE_SUCCESS: 'GET_WEBSITE_SUCCESS',
    UPDATE_WEBSITE_REQUEST: 'UPDATE_WEBSITE_REQUEST',
    UPDATE_WEBSITE_SUCCESS: 'UPDATE_WEBSITE_SUCCESS',

    GET_TEMPLATES_REQUEST: 'GET_TEMPLATES_REQUEST',
    GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',

    GET_STORE_ORDERS_REQUEST: 'GET_STORE_ORDERS_REQUEST',
    GET_STORE_ORDERS_SUCCESS: 'GET_STORE_ORDERS_SUCCESS',

    GET_ONE_ORDER_REQUEST: 'GET_ONE_ORDER_REQUEST',
    GET_ONE_ORDER_SUCCESS: 'GET_ONE_ORDER_SUCCESS',

    GET_STORE_PAYMENT_CONFIG_REQUEST: 'GET_STORE_PAYMENT_CONFIG_REQUEST',
    GET_STORE_PAYMENT_CONFIG_SUCCESS: 'GET_STORE_PAYMENT_CONFIG_SUCCESS',

    GET_ALL_PAYMENT_CONFIG_REQUEST: 'GET_ALL_PAYMENT_CONFIG_REQUEST',
    GET_ALL_PAYMENT_CONFIG_SUCCESS: 'GET_ALL_PAYMENT_CONFIG_SUCCESS',

    UPDATE_STORE_PAYMENT_CONFIG_REQUEST: 'UPDATE_STORE_PAYMENT_CONFIG_REQUEST',
    UPDATE_STORE_PAYMENT_CONFIG_SUCCESS: 'UPDATE_STORE_PAYMENT_CONFIG_SUCCESS',

    CHANGE_ORDER_STATUS_REQUEST: 'CHANGE_ORDER_STATUS_REQUEST',
    CHANGE_ORDER_STATUS_SUCCESS: 'CHANGE_ORDER_STATUS_SUCCESS',

    GET_ORDER_HISTORY_REQUEST: 'GET_ORDER_HISTORY_REQUEST',
    GET_ORDER_HISTORY_SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',

    GET_STORE_QR_REQUEST: 'GET_STORE_QR_REQUEST',
    GET_STORE_QR_SUCCESS: 'GET_STORE_QR_SUCCESS',

    GENERATE_INVOICE_REQUEST: 'GENERATE_INVOICE_REQUEST',
    GENERATE_INVOICE_SUCCESS: 'GENERATE_INVOICE_SUCCESS',

    GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
    GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',

    GET_ONE_INVOICE_REQUEST: 'GET_ONE_INVOICE_REQUEST',
    GET_ONE_INVOICE_SUCCESS: 'GET_ONE_INVOICE_SUCCESS',

    SEND_INVOICE_EMAIL_REQUEST: 'SEND_INVOICE_EMAIL_REQUEST',
    SEND_INVOICE_EMAIL_SUCCESS: 'SEND_INVOICE_EMAIL_SUCCESS',

    FILTER_ORDER_REQUEST: 'FILTER_ORDER_REQUEST',
    FILTER_ORDER_SUCCESS: 'FILTER_ORDER_SUCCESS',

    GET_WALLET_TRANSACTIONS_REQUEST:'GET_WALLET_TRANSACTIONS_REQUEST',
    GET_WALLET_TRANSACTIONS_SUCCESS:'GET_WALLET_TRANSACTIONS_SUCCESS',

    TOP_SELLING_ITEMS_REQUEST: 'TOP_SELLING_ITEMS_REQUEST',
    TOP_SELLING_ITEMS_SUCCESS: 'TOP_SELLING_ITEMS_SUCCESS',

    PRODUCT_VIEWS_REQUEST:'PRODUCT_VIEWS_REQUEST',
    PRODUCT_VIEWS_SUCCESS:'PRODUCT_VIEWS_SUCCESS',

    CHECKOUT_VIEWS_REQUEST:'CHECKOUT_VIEWS_REQUEST',
    CHECKOUT_VIEWS_SUCCESS:'CHECKOUT_VIEWS_SUCCESS',

    SALES_REPORT_REQUEST:'SALES_REPORT_REQUEST',
    SALES_REPORT_SUCCESS:'SALES_REPORT_SUCCESS',

    PROFIT_LOSS_REQUEST:'PROFIT_LOSS_REQUEST',
    PROFIT_LOSS_SUCCESS:'PROFIT_LOSS_SUCCESS',

    CHECKOUT_LOCATION_REQUEST:"CHECKOUT_LOCATION_REQUEST",
    CHECKOUT_LOCATION_SUCCESS:"CHECKOUT_LOCATION_SUCCESS",
}