
import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const invoice = {
    // To generate invoice
    generate_invoice: (data) => {
        return async dispatch => {
            dispatch(shopActions.generateInvoiceRequest());

            await axios.post(`${baseUrl}/ecommerce/invoices`, data)
                .then((res) => {
                    console.log(res.data)
                    dispatch(shopActions.generateInvoiceSuccess(res.data.data));
                    alert('Invoice generated successfully')
                    history.push('/invoice')
                });
        }
    },

    get_invoice: (id) => {
        return async dispatch => {
            dispatch(shopActions.getInvoiceRequest());

            await axios.get(`${baseUrl}/ecommerce/invoice/user/${id}`)
                .then((res) => {
                    dispatch(shopActions.getInvoiceSuccess(res.data.data));
                });
        }
    },

    get_one_invoice: (invoice_number) => {
        return async dispatch => {
            dispatch(shopActions.getOneInvoiceRequest());

            await axios.get(`${baseUrl}/ecommerce/invoice/${invoice_number}`)
                .then((res) => {
                    dispatch(shopActions.getOneInvoiceSuccess(res.data.data));
                });
        }
    },

    send_email: (data) => {
        return async dispatch => {
            dispatch(shopActions.sendInvoiceEmailRequest());

            await axios.post(`${baseUrl}/ecommerce/email/invoice`, data)
                .then((res) => {
                    dispatch(shopActions.sendInvoiceEmailSuccess(res.data.data));
                    alert('Email sent successfully')
                });
        }
    },

}
export default invoice;