import { shopConstants } from '../actions/types'

const initialState = {
    data: null,
};

function currentStore(state = initialState, action) {
    switch (action.type) {
        case shopConstants.CURRENT_STORE:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state
    }
}

export default currentStore;