import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../components/Navigation'
import { StatusHistoryModal } from '../../components/Orders/StatusHistoryModal';
import orders from '../../services/orderService';

export const OrderDetails = (props) => {
    const id = props.match.params.slug;
    const dispatch = useDispatch();
    const oneOrder = useSelector(state => state.getOneOrder.oneOrder);
    const orderHistory = useSelector(state => state.orderHistory.data)
    const [show, setShow] = useState(false);


    function getOneOrder() {
        dispatch(orders.get_one_order(id))
    }

    function getOrderHistory() {
        dispatch(orders.get_order_history(id))
    }

    let total;

    if (oneOrder.orderDetails) {
        total = oneOrder.orderDetails.reduce((sum, price) => sum + Number(price.unitPrice) * price.quantity, 0)
    }

    useEffect(() => {
        getOneOrder()
        getOrderHistory()
    }, []);

    return (
        <Navigation {...props}>
            <h4 className='page-title'>Order Details</h4>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <hr />
                <div >
                    <Row>
                        <Col lg={2} sm={4} xs={4}>
                            Order No:
                        </Col>
                        <Col lg={2} sm={4} xs={4}>
                            {oneOrder.orderNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} sm={4} xs={4}>
                            Items:
                        </Col>
                        <Col lg={2} sm={4} xs={4}>
                            {oneOrder.orderDetails && oneOrder.orderDetails.length} items
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={2} sm={4} xs={4}>
                            Place on:
                        </Col>
                        <Col lg={4} sm={4} xs={4}>
                            {new Date(oneOrder.createdAt).toLocaleString()}
                        </Col>
                    </Row>


                    <Row>
                        <Col lg={2} sm={4} xs={4}>
                            Total:
                        </Col>
                        <Col lg={2} sm={4} xs={4}>
                            N{Number(total).toLocaleString()}
                        </Col>
                    </Row>

                    {/* <div style={{ padding: "3px", textAlign: 'center', width: '25%', borderRadius: '5px', color: '#fff', fontSize: "10px", backgroundColor: 'green' }}>DELIVERED</div> */}
                    <Row>
                        {/* <Col lg={6} sm={6} xs={6}>
                            <div style={{ padding: "3px", textAlign: 'center', width: '25%', borderRadius: '5px', color: '#fff', fontSize: "10px", backgroundColor: 'gray' }}>PENDING</div>
                        </Col> */}
                        <Col lg={6} sm={6} xs={6}>
                            <Button className='status-history-button' onClick={() => setShow(true)}>SEE STATUS HISTORY</Button>
                        </Col>
                    </Row>
                    <StatusHistoryModal open={show} close={() => setShow(false)} orderHistory={orderHistory} />
                    <hr />
                </div>

                <Row gutter={24}>
                    <Col lg={12} sm={24} xs={24}>
                        <h4 style={{ fontWeight: 'bolder', marginTop: '30px' }}>PAYMENT DETAILS</h4>
                        <div className='payment-box'>
                            <Row style={{ padding: '20px' }}>
                                <Col lg={10} sm={10} xs={10}>
                                    <p>PAID BY</p>
                                    <p>TRANSACTION REFERENCE: </p>
                                    <p>PAYMENT CHANNEL</p>
                                    <p>PAYMENT STATUS</p>
                                </Col>
                                <Col lg={10} sm={10} xs={10} style={{ fontWeight: 'bolder' }}>
                                    <p>{oneOrder.userObject && oneOrder.userObject.fullName}</p>
                                    <p>ABCD454545</p>
                                    <p>QR payment</p>
                                    <p>Confirmed</p>
                                </Col>
                            </Row>
                        </div>

                        <h4 style={{ fontWeight: 'bolder', marginTop: '30px' }}>SHIPPING DETAILS</h4>

                        <div className='payment-box'>
                            <Row style={{ padding: '20px' }}>
                                <Col lg={10} sm={10} xs={10}>
                                    <p>SHIP TO</p>
                                    <p>EMAIL</p>
                                    <p>PHONE NUMBER</p>
                                    <p>DELIVERY ADRESS: </p>
                                    <p>PAYMENT STATUS</p>
                                </Col>
                                <Col lg={10} sm={10} xs={10} style={{ fontWeight: 'bolder' }}>
                                    <p>{oneOrder.userObject && oneOrder.userObject.fullName} </p>
                                    <p>{oneOrder.userObject && oneOrder.userObject.email} </p>
                                    <p>{oneOrder.userObject && oneOrder.userObject.phoneNumber} </p>
                                    <p>TEXAS</p>
                                    <p style={{ color: 'red' }}>Pending</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={12} sm={24} xs={24}>

                        <h4 style={{ fontWeight: 'bolder', marginTop: '30px' }}>ITEMS IN YOUR ORDER</h4>
                        {
                            oneOrder.orderDetails && oneOrder.orderDetails.map((data, i) => {
                                return (
                                    <div key={i}>
                                        <Row className='order-summary' style={{ alignItems: 'center', marginBottom: '20px' }}>
                                            <Col lg={6} sm={6} xs={6}>
                                                <p style={{ fontWeight: 'bolder' }}>On {new Date(data.createdAt).toDateString()}</p>
                                                <img src={data.Product && data.Product.productImagesUrl} alt='product' width='80' height='70' />

                                            </Col>
                                            <Col lg={12} sm={12} xs={12}>
                                                <p>{data.Product && data.Product.productName}</p>
                                                <p>QTY: {data.quantity}</p>
                                                <p>Price: NGN{data.unitPrice}</p>

                                            </Col>
                                        </Row>

                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            </div>
        </Navigation>
    )
}
