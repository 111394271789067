import React from "react";
import { Table } from "ant-table-extensions";
import { salesColumns } from "../../components/data";

export const SalesReport = (props) => {
  const dataSource =
    props.salesReport &&
    props.salesReport.map((pro, i) => {
      return {
        key: i,
        name: pro.productId,
        price: pro.unitPrice,
        quantity: pro.quantity,
        total: pro.total,
      };
    });

  return (
    <div>
      <Table
        scroll={{ x: 1400, y: 600 }}
        dataSource={dataSource}
        columns={salesColumns}
        searchable
        // onChange={onChange}
        sticky
      />
    </div>
  );
};
