import { Button, Input, Form, Col, Row, Select, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation';
import { connect, useSelector } from 'react-redux';
import store from '../../services/storeService';
import { IKContext, IKUpload } from 'imagekitio-react';
import { HeaderBar } from '../../Apperance';
import product from '../../services/productService';
import supplier from '../../services/supplierService';
import { Option } from 'antd/lib/mentions';

const publicKey = 'public_sYCPM1kMSIovPWZMi7Mj83OMCBA=';
const authenticationEndpoint = 'https://api.test.getripay.com/api/v1/ecommerce/auth_params';
const urlEndpoint = 'https://ik.imagekit.io/rnh8t89owod';

function EditProduct(props) {
    const current = useSelector(state => state.current.data)
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const id = props.match.params.slug;
    const storeId = props.store ? props.store.id : '';
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tags, setTags] = useState(['Trendy', "Tech"])
    const [images, setImages] = useState([]);
    const [fileName, setFileName] = useState('');
    const [resUrl, setResUrl] = useState('');
    const [price, setPrice] = useState('');
    const user = useSelector(state => state.user.data)
    function handleChangeTags(value) {
        setTags(value.toString())
    };

    const pro = props.product[0] ? props.product[0] : [];

    function submitTag() {
        props.update_tag(id, tags).then(() => {
            message.success('Product tags updated successfully')
        })
    };

    function updatePrice() {
        const res = {
            productId: pro ? pro.id : '',
            amount: price
        }
        props.update_price(res).then(() => {
            message.success('Product tags updated successfully')
        })
    }

    useEffect(() => {
        if (user.id) {
            props.get_one_store(current)
        }
    }, [])

    useEffect(() => {
        props.get_one_product(id)
        props.get_supplies(id)
    }, []);

    useEffect(() => {
        if (storeId) {
            props.get_supplier(storeId)
        }
    }, [storeId])

    function setImg() {
        if (pro) {
            setImages(([pro.productImagesUrl]).toString().split(','))
        }
    }

    useEffect(() => {
        setImg();
    }, [props.product[0]])

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        const supplier = {
            storeId: storeId,
            productSupplierName: values.productSupplierName,
            productSupplierAddress: values.productSupplierAddress,
        }
        props.create_supplier(supplier).then(() => {
            message.success('Supplier created successfully')
            props.get_supplier(storeId)
        })
    };

    function submitImage() {
        const data = {
            productImagesUrl: images && images.toString(),
            productId: id
        }
        if (data.productImagesUrl && data.productId) {
            props.update_product(data)
        }
    };

    const onSuccess = res => {
        setResUrl(res.url)
        res.url && images.push(res.url)
        console.log(images)
    };

    const submitSupplies = (values) => {
        const supplies = {
            productId: id,
            quantity: values.quantity,
            amount: values.amount,
            supplierId: values.supplierId
        }
        props.submit_supplies(supplies).then(() => {
            message.success('Supplies submiitted')
        })
    };
    return (
        <Navigation {...props}>
            <HeaderBar topic={`Edit -  ${pro.productName}`} />
            <Row className='create-store-page'>
                <Col span={24} lg={9} sm={24} xs={24}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h4 style={{ textAlign: 'center', marginBottom: '15px', fontWeight: 'bolder' }}>Product Summary</h4>
                        {pro &&
                            <div className='create-store-box' >
                                <p style={{ borderBottom: '1px solid #ccc', padding: '7px' }}>Product Name: {pro.productName}</p>
                                <p style={{ borderBottom: '1px solid #ccc', padding: '7px' }}>Description: {pro.productDescription}</p>
                                <p style={{ borderBottom: '1px solid #ccc', padding: '7px' }}>Unit Price: NGN {pro.updatedPrice}</p>
                                <p style={{ borderBottom: '1px solid #ccc', padding: '7px' }}>Category: {pro.categoryName} </p>
                                <p style={{ borderBottom: '1px solid #ccc', padding: '7px' }}>Sub Category: {pro.productSubCategory}</p>
                                <p style={{ borderBottom: '1px solid #ccc', padding: '7px' }}>Product Tags: {pro.productTags}</p>

                                <div style={{ padding: '7px' }}>
                                    <span >Images: </span> {' '}
                                    {images.map((d, i) => {
                                        return (
                                            <img key={i} src={d} alt='product' width='120' height='100' />
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }

                    </div>
                </Col>
                <Col span={24} lg={8} sm={24} xs={24}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h4 style={{ textAlign: 'center', marginBottom: '15px', fontWeight: 'bolder' }}>Update Product</h4>

                        <div className='edit-product-box' >
                            <p>Update Product Price</p>
                            <Form.Item name="productPrice" rules={[{ required: true, message: 'Please enter a price' }]} >
                                <Input placeholder='New Unit Price' value={price} onChange={(e) => setPrice(e.target.value)} />
                            </Form.Item>
                            <Form.Item>
                                {props.loading ?
                                    <Button type="primary" loading={props.loading}>Update Product Price</Button>
                                    :
                                    <Button type="primary" onClick={updatePrice}>Update Product Price</Button>
                                }

                            </Form.Item>
                        </div>

                        <div className='edit-product-box' >
                            {props.supplier.length === 0 ?
                                <div style={{ marginBottom: '20px', borderBottom: '2px solid #ccc', textAlign: "center" }}>
                                    You currently do not have any supplier. <br />Click on the button below to add a supplier

                                    <div style={{ textAlign: 'center', marginBottom: "10px" }}>
                                        <Button type="primary" onClick={showModal}>
                                            Add Supplier
                                     </Button>
                                    </div>

                                    <Modal footer={null}
                                        title="Supplier Details" visible={isModalVisible} onCancel={handleCancel}>
                                        <Form
                                            form={form}
                                            name="register"
                                            onFinish={onFinish}
                                            scrollToFirstError >

                                            <Form.Item name="productSupplierName" style={{ textAlign: "center" }}
                                                rules={[{ required: true, message: 'Please enter the Supplier Name' }]}>
                                                <Input placeholder='Supplier Name' />
                                            </Form.Item>
                                            <Form.Item name="productSupplierAddress" style={{ textAlign: "center" }}
                                                rules={[{ required: true, message: 'Please enter the Supplier Address' }]}>
                                                <Input placeholder='Supplier Address' />
                                            </Form.Item>

                                            <Form.Item style={{ textAlign: "center" }}>
                                                <Button type="primary" htmlType="submit">
                                                    Submit
                                                 </Button>
                                            </Form.Item>
                                        </Form>

                                    </Modal>

                                </div>
                                :
                                ''
                            }
                            {props.supplier.length !== 0 &&
                                <>
                                    <div style={{ textAlign: 'right', marginBottom: "10px" }}>
                                        <Button type="primary" onClick={showModal}>
                                            Add New Supplier
                                     </Button>
                                    </div>

                                    <Modal footer={null}
                                        title="Supplier Details" visible={isModalVisible} onCancel={handleCancel}>
                                        <Form
                                            form={form}
                                            name="register"
                                            onFinish={onFinish}
                                            scrollToFirstError >
                                            <p>Supplier Name</p>
                                            <Form.Item name="productSupplierName" style={{ textAlign: "center" }}
                                                rules={[{ required: true, message: 'Please enter the Supplier Name' }]}>
                                                <Input placeholder='Supplier Name' />
                                            </Form.Item>

                                            <p>Supplier Address</p>
                                            <Form.Item name="productSupplierAddress" style={{ textAlign: "center" }}
                                                rules={[{ required: true, message: 'Please enter the Supplier Address' }]}>
                                                <Input placeholder='Supplier Address' />
                                            </Form.Item>

                                            <Form.Item style={{ textAlign: "center" }}>
                                                <Button type="primary" htmlType="submit">
                                                    Submit
                                                 </Button>
                                            </Form.Item>
                                        </Form>

                                    </Modal>

                                </>

                            }

                            {props.supplies[0] ?
                                <p style={{ textAlign: 'center' }}>Your last supply of {props.supplies[0].quantity}
                                    {' '} was from {props.supplies[0].supplierId}. <br /> Fill the boxes below to update it
                            </p>
                                :
                                ''
                            }

                            <Form
                                form={form2}
                                name="supplier"
                                onFinish={submitSupplies}
                                scrollToFirstError >
                                <Form.Item name="supplierId" rules={[{ required: true, message: 'Please select your supplier' }]} >
                                    <Select placeholder="Please select your supplier" >
                                        {props.supplier.map((sup, i) => {
                                            return (

                                                <Option key={i} value={sup.id}>{sup.productSupplierName}</Option>
                                            )
                                        })

                                        }
                                    </Select>
                                </Form.Item>
                                <p style={{ margin: '0px' }}>Quantity</p>
                                <Form.Item name="quantity"
                                    rules={[{ required: true, message: 'Please enter the quantity' }]}>
                                    <Input placeholder='Quantity' />
                                </Form.Item>
                                <p style={{ margin: '0px' }}>Unit Price</p>
                                <Form.Item name="amount"
                                    rules={[{ required: true, message: 'Please enter the unit price for one item ' }]}>
                                    <Input placeholder='Unit Price' />
                                </Form.Item>

                                <Form.Item>
                                    {props.supplier.length === 0 ?
                                        <div style={{ backgroundColor: '#ccc', color: "fff", borderRadius: '10px', padding: "10px", width: '150px', textAlign: 'center' }} disabled={props.supplier.length === 0}>Add Stock</div>
                                        :
                                        <Button type="primary" htmlType="submit">Submit Stock</Button>
                                    }

                                </Form.Item>

                            </Form>

                        </div>
                    </div>


                </Col>
                <Col span={24} lg={7}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h4 style={{ textAlign: 'center', marginBottom: '15px', fontWeight: 'bolder' }}>Update others</h4>


                        <div className='edit-product-box' >
                            <p>Update Product Images</p>
                            {images.map((d, i) => {
                                return (
                                    <img key={i} src={d} alt='product' width='100' height='50' />
                                )
                            })

                            }

                            <IKContext
                                urlEndpoint={urlEndpoint}
                                publicKey={publicKey}
                                authenticationEndpoint={authenticationEndpoint}
                            >
                                <label for="imageUpload" style={{ margin: '10px', padding: '5px', display: 'block', border: '2px solid #ccc' }}>Click to add more images +</label>
                                <IKUpload
                                    id='imageUpload'
                                    style={{ display: 'none' }}
                                    fileName="product.png"
                                    //onError={onError}
                                    onChange={(e) => setFileName(e.target.files)}
                                    onSuccess={onSuccess}
                                // onChange={onSuccess}
                                />

                                <span>{fileName[0] && fileName[0].name}</span>
                            </IKContext>

                            <Form.Item>
                                <Button disabled={resUrl === ''} type="primary" onClick={submitImage}>Update Image</Button>
                            </Form.Item>
                        </div>

                        <div className='edit-product-box' >
                            <p>Update Product Tags</p>
                            <Form.Item name="productTags" rules={[{ required: true, message: 'Please choose product tags', type: 'array' }]} >
                                <Select mode="multiple" placeholder="Click to update your product tag" defaultValue={tags} onChange={handleChangeTags}>
                                    <Option value="Trendy">Trendy</Option>
                                    <Option value="Tech">Tech</Option>
                                    <Option value="Hardware">Hardware</Option>
                                    <Option value="Fashion">Fashion</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                {props.loading ?
                                    <Button type="primary" loading={props.loading}>Update Tag</Button>
                                    :
                                    <Button type="primary" onClick={submitTag}>Update Tag</Button>
                                }

                            </Form.Item>
                        </div>
                    </div>

                </Col>
            </Row>

        </Navigation >
    )
}

const mapStateToProps = (state) => {
    return {
        store: state.getOneStore.data,
        product: state.getOneProduct.data,
        supplier: state.getSupplier.data,
        supplies: state.getSupplies.data,
        loading: state.updateProduct.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_one_store: (id) => dispatch(store.get_one_store(id)),
        get_one_product: (id) => dispatch(product.get_one_product(id)),
        get_supplier: (storeId) => dispatch(supplier.get_supplier(storeId)),
        create_supplier: (data) => dispatch(supplier.create_supplier(data)),
        submit_supplies: (supplies) => dispatch(supplier.submit_supplies(supplies)),
        get_supplies: (productId) => dispatch(supplier.get_supplies(productId)),
        update_tag: (id, tag) => dispatch(product.update_tag(id, tag)),
        update_price: (data) => dispatch(product.update_price(data)),
        update_product: (data) => dispatch(product.update_product(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)