import { shopConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: [],
};

function getStore(state = initialState, action) {
    switch (action.type) {
        case shopConstants.GET_STORE_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.GET_STORE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        default:
            return state
    }
}

export default getStore;