import { shopConstants } from "./types";

export const shopActions = {
    getUserRequest,
    getUserSuccess,
    getUserFailure,
    loginRequest,
    loginSuccess,
    loginFailure,
    currentStore,
    getAccountBalanceRequest,
    getAccountBalanceSuccess,
    createStoreRequest,
    createStoreSuccess,
    editStoreRequest,
    editStoreSuccess,
    createProductRequest,
    createProductSuccess,
    updateProductRequest,
    updateProductSuccess,
    getProductsRequest,
    getProductsSuccess,
    getOneProductRequest,
    getOneProductSuccess,
    createStoreSuccess,
    getStoreRequest,
    getStoreSuccess,
    getOneStoreRequest,
    getOneStoreSuccess,
    getIndustriesRequest,
    getIndustriesSuccess,
    productReqRequest,
    productTypesSuccess,
    productCategoriesSuccess,
    productPropertiesSuccess,
    createSupplierRequest,
    createSupplierSuccess,
    getSupplierRequest,
    getSupplierSuccess,
    submitSuppliesRequest,
    submitSuppliesSuccess,
    getSuppliesRequest,
    getSuppliesSuccess,
    getWebsiteRequest,
    getWebsiteSuccess,
    getTemplatesRequest,
    getTemplatesSuccess,
    updateWebsiteRequest,
    updateWebsiteSuccess,
    getStoreOrdersRequest,
    getStoreOrdersSuccess,
    getOneOrderRequest,
    getOneOrderSuccess,
    getStorePaymentConfigRequest,
    getStorePaymentConfigSuccess,
    getAllPaymentConfigRequest,
    getAllPaymentConfigSuccess,
    updateStorePaymentConfigRequest,
    updateStorePaymentConfigSuccess,
    changeOrderStatusRequest,
    changeOrderStatusSuccess,
    getOrderHistoryRequest,
    getOrderHistorySuccess,
    getStoreQrRequest,
    getStoreQrSuccess,
    generateInvoiceRequest,
    generateInvoiceSuccess,
    getInvoiceRequest,
    getInvoiceSuccess,
    getOneInvoiceRequest,
    getOneInvoiceSuccess,
    sendInvoiceEmailRequest,
    sendInvoiceEmailSuccess,
    filterOrderRequest,
    filterOrderSuccess,
    getWalletTransactionsRequest,
    getWalletTransactionsSuccess,  
    topSellingItemsRequest,
    topSellingItemsSuccess,
    productViewsRequest,
    productViewsSuccess,
    checkoutViewsRequest,
    checkoutViewsSuccess,
    salesReportRequest,
    salesReportSuccess,
    profitLossRequest,
    profitLossSuccess,
    checkoutLocationRequest,
    checkoutLocationSuccess
}

// CREATING A USER'S DETAILS
function getUserRequest() {
    return {
        type: shopConstants.GET_USER_REQUEST,
    };
};

function getUserSuccess(payload) {
    return {
        type: shopConstants.GET_USER_SUCCESS,
        payload,
    };
};

function getUserFailure(payload) {
    return {
        type: shopConstants.GET_USER_FAILURE,
        payload
    };
};


// LOGIN
function loginRequest() {
    return {
        type: shopConstants.LOGIN_REQUEST,
    };
};

function loginSuccess(payload) {
    return {
        type: shopConstants.LOGIN_SUCCESS,
        payload,
    };
};

function loginFailure(payload) {
    return {
        type: shopConstants.LOGIN_FAILURE,
        payload,
    };
};

// Getting and switching a store
function currentStore(payload) {
    return {
        type: shopConstants.CURRENT_STORE,
        payload
    };
};

// GET A USER'S ACCOUNT BALANCE
function getAccountBalanceRequest() {
    return {
        type: shopConstants.GET_ACCOUNT_BALANCE_REQUEST,
    };
};

function getAccountBalanceSuccess(payload) {
    return {
        type: shopConstants.GET_ACCOUNT_BALANCE_SUCCESS,
        payload,
    };
};


// CREATING A NEW STORE
function createStoreRequest() {
    return {
        type: shopConstants.CREATE_STORE_REQUEST,
    };
};

function createStoreSuccess(payload) {
    return {
        type: shopConstants.CREATE_STORE_SUCCESS,
        payload,
    };
};

// EDITING A STORE
function editStoreRequest() {
    return {
        type: shopConstants.EDIT_STORE_REQUEST,
    };
};

function editStoreSuccess(payload) {
    return {
        type: shopConstants.EDIT_STORE_SUCCESS,
        payload,
    };
};

//GETTING ALL STORES FOR A USER
function getStoreRequest() {
    return {
        type: shopConstants.GET_STORE_REQUEST,
    };
};

function getStoreSuccess(payload) {
    return {
        type: shopConstants.GET_STORE_SUCCESS,
        payload,
    };
};

//GETTING One Store
function getOneStoreRequest() {
    return {
        type: shopConstants.GET_ONE_STORE_REQUEST,
    };
};

function getOneStoreSuccess(payload) {
    return {
        type: shopConstants.GET_ONE_STORE_SUCCESS,
        payload,
    };
};


// GETTING THE LIST OF ALL INDUSTRIES
function getIndustriesRequest() {
    return {
        type: shopConstants.GET_INDUSTRIES_REQUEST,
    };
};

function getIndustriesSuccess(payload) {
    return {
        type: shopConstants.GET_INDUSTRIES_SUCCESS,
        payload,
    };
};

// GETTING THE LIST OF ALL INDUSTRIES
function productReqRequest() {
    return {
        type: shopConstants.PRODUCT_REQ_REQUEST,
    };
};

function productCategoriesSuccess(payload) {
    return {
        type: shopConstants.PRODUCT_CATEGORIES_SUCCESS,
        payload,
    };
};

function productTypesSuccess(payload) {
    return {
        type: shopConstants.PRODUCT_TYPES_SUCCESS,
        payload,
    };
};

function productPropertiesSuccess(payload) {
    return {
        type: shopConstants.PRODUCT_PROPERTIES_SUCCESS,
        payload,
    };
};

// CREATING A NEW PRODUCT
function createProductRequest() {
    return {
        type: shopConstants.CREATE_PRODUCT_REQUEST,
    };
};

function createProductSuccess(payload) {
    return {
        type: shopConstants.CREATE_PRODUCT_SUCCESS,
        payload,
    };
};

// UPDATING A PROUDUCT
function updateProductRequest() {
    return {
        type: shopConstants.UPDATE_PRODUCT_REQUEST,
    };
};

function updateProductSuccess(payload) {
    return {
        type: shopConstants.UPDATE_PRODUCT_SUCCESS,
        payload,
    };
};



// GETTING ALL PRODUCTS
function getProductsRequest() {
    return {
        type: shopConstants.GET_PRODUCTS_REQUEST,
    };
};

function getProductsSuccess(payload) {
    return {
        type: shopConstants.GET_PRODUCTS_SUCCESS,
        payload,
    };
};

// GETTING ONE PRODUCT
function getOneProductRequest() {
    return {
        type: shopConstants.GET_ONE_PRODUCT_REQUEST,
    };
};

function getOneProductSuccess(payload) {
    return {
        type: shopConstants.GET_ONE_PRODUCT_SUCCESS,
        payload,
    };
};



// CREATE SUPPLIER 
function createSupplierRequest() {
    return {
        type: shopConstants.CREATE_SUPPLIER_REQUEST,
    };
};

function createSupplierSuccess(payload) {
    return {
        type: shopConstants.CREATE_SUPPLIER_SUCCESS,
        payload,
    };
};

// GETTING ONE PRODUCT
function getSupplierRequest() {
    return {
        type: shopConstants.GET_SUPPLIER_REQUEST,
    };
};

function getSupplierSuccess(payload) {
    return {
        type: shopConstants.GET_SUPPLIER_SUCCESS,
        payload,
    };
};

// SUBMIT SUPPLIES FOR A PARTICULAR PRODUCT 
function submitSuppliesRequest() {
    return {
        type: shopConstants.SUBMIT_SUPPLIES_REQUEST,
    };
};

function submitSuppliesSuccess(payload) {
    return {
        type: shopConstants.SUBMIT_SUPPLIES_SUCCESS,
        payload,
    };
};

// GET SUPPLIES FOR A PARTICULAR PRODUCT 
function getSuppliesRequest() {
    return {
        type: shopConstants.GET_SUPPLIES_REQUEST,
    };
};

function getSuppliesSuccess(payload) {
    return {
        type: shopConstants.GET_SUPPLIES_SUCCESS,
        payload,
    };
};


// GET A USER'S WEBSITE
function getWebsiteRequest() {
    return {
        type: shopConstants.GET_WEBSITE_REQUEST,
    };
};

function getWebsiteSuccess(payload) {
    return {
        type: shopConstants.GET_WEBSITE_SUCCESS,
        payload,
    };
};


// GET ALL AVAILABLE TEMPLATES 
function getTemplatesRequest() {
    return {
        type: shopConstants.GET_TEMPLATES_REQUEST,
    };
};

function getTemplatesSuccess(payload) {
    return {
        type: shopConstants.GET_TEMPLATES_SUCCESS,
        payload,
    };
};


// UPDATE A USER'S WEBSITE
function updateWebsiteRequest() {
    return {
        type: shopConstants.UPDATE_WEBSITE_REQUEST,
    };
};

function updateWebsiteSuccess(payload) {
    return {
        type: shopConstants.UPDATE_WEBSITE_SUCCESS,
        payload,
    };
};

// GET THE ORDERS ON A PARTICULAR STORE
function getStoreOrdersRequest() {
    return {
        type: shopConstants.GET_STORE_ORDERS_REQUEST,
    };
};

function getStoreOrdersSuccess(payload) {
    return {
        type: shopConstants.GET_STORE_ORDERS_SUCCESS,
        payload,
    };
};

// GET ONE ORDER
function getOneOrderRequest() {
    return {
        type: shopConstants.GET_ONE_ORDER_REQUEST,
    };
};

function getOneOrderSuccess(payload) {
    return {
        type: shopConstants.GET_ONE_ORDER_SUCCESS,
        payload,
    };
};


// GET PAYMENT CONFIGURATIONS FOR A STORE
function getStorePaymentConfigRequest() {
    return {
        type: shopConstants.GET_STORE_PAYMENT_CONFIG_REQUEST,
    };
};

function getStorePaymentConfigSuccess(payload) {
    return {
        type: shopConstants.GET_STORE_PAYMENT_CONFIG_SUCCESS,
        payload,
    };
};

// GET ALL AVAILABLE PAYMENT CONFIGURATIONS
function getAllPaymentConfigRequest() {
    return {
        type: shopConstants.GET_ALL_PAYMENT_CONFIG_REQUEST,
    };
};

function getAllPaymentConfigSuccess(payload) {
    return {
        type: shopConstants.GET_ALL_PAYMENT_CONFIG_SUCCESS,
        payload,
    };
};

// UPDATE PAYMENT CONFIGURATIONS FOR A STORE
function updateStorePaymentConfigRequest() {
    return {
        type: shopConstants.UPDATE_STORE_PAYMENT_CONFIG_REQUEST,
    };
};

function updateStorePaymentConfigSuccess(payload) {
    return {
        type: shopConstants.UPDATE_STORE_PAYMENT_CONFIG_SUCCESS,
        payload,
    };
};


// CHNAGE ORDER STATUS
function changeOrderStatusRequest() {
    return {
        type: shopConstants.CHANGE_ORDER_STATUS_REQUEST,
    };
};

function changeOrderStatusSuccess(payload) {
    return {
        type: shopConstants.CHANGE_ORDER_STATUS_SUCCESS,
        payload,
    };
};

// GET ORDER HISTORY
function getOrderHistoryRequest() {
    return {
        type: shopConstants.GET_ORDER_HISTORY_REQUEST,
    };
};

function getOrderHistorySuccess(payload) {
    return {
        type: shopConstants.GET_ORDER_HISTORY_SUCCESS,
        payload,
    };
};


// GET ORDER HISTORY
function filterOrderRequest() {
    return {
        type: shopConstants.FILTER_ORDER_REQUEST,
    };
};

function filterOrderSuccess(payload) {
    return {
        type: shopConstants.FILTER_ORDER_SUCCESS,
        payload,
    };
};



function getStoreQrRequest(payload) {
    return {
        type: shopConstants.GET_STORE_QR_REQUEST,
        payload,
    };
};

function getStoreQrSuccess(payload) {
    return {
        type: shopConstants.GET_STORE_QR_SUCCESS,
        payload,
    };
};


function generateInvoiceRequest(payload) {
    return {
        type: shopConstants.GENERATE_INVOICE_REQUEST,
        payload,
    };
};

function generateInvoiceSuccess(payload) {
    return {
        type: shopConstants.GENERATE_INVOICE_SUCCESS,
        payload,
    };
};

function getInvoiceRequest(payload) {
    return {
        type: shopConstants.GET_INVOICE_REQUEST,
        payload,
    };
};

function getInvoiceSuccess(payload) {
    return {
        type: shopConstants.GET_INVOICE_SUCCESS,
        payload,
    };
};


function getOneInvoiceRequest(payload) {
    return {
        type: shopConstants.GET_ONE_INVOICE_REQUEST,
        payload,
    };
};

function getOneInvoiceSuccess(payload) {
    return {
        type: shopConstants.GET_ONE_INVOICE_SUCCESS,
        payload,
    };
};


// SEND INVOICE VIA EMAIL

function sendInvoiceEmailRequest() {
    return {
        type: shopConstants.SEND_INVOICE_EMAIL_REQUEST,

    };
}

function sendInvoiceEmailSuccess() {
    return {
        type: shopConstants.SEND_INVOICE_EMAIL_SUCCESS,

    };
}

// GET WALLET TRANSACTIONS
function getWalletTransactionsRequest() {
    return {
        type: shopConstants.GET_WALLET_TRANSACTIONS_REQUEST,
    };
};

function getWalletTransactionsSuccess(payload) {
    return {
        type: shopConstants.GET_WALLET_TRANSACTIONS_SUCCESS,
        payload,
    };
};

// STORE REPORTS
function topSellingItemsRequest() {
    return {
        type: shopConstants.TOP_SELLING_ITEMS_REQUEST,
    };
};

function topSellingItemsSuccess(payload) {
    return {
        type: shopConstants.TOP_SELLING_ITEMS_SUCCESS,
        payload,
    };
};

function productViewsRequest() {
    return {
        type: shopConstants.PRODUCT_VIEWS_REQUEST,
    };
};

function productViewsSuccess(payload) {
    return {
        type: shopConstants.PRODUCT_VIEWS_SUCCESS,
        payload,
    };
};

function checkoutViewsRequest() {
    return {
        type: shopConstants.CHECKOUT_VIEWS_REQUEST,
    };
};

function checkoutViewsSuccess(payload) {
    return {
        type: shopConstants.CHECKOUT_VIEWS_SUCCESS,
        payload,
    };
};


function salesReportRequest() {
    return {
        type: shopConstants.SALES_REPORT_REQUEST,
    };
};

function salesReportSuccess(payload) {
    return {
        type: shopConstants.SALES_REPORT_SUCCESS,
        payload,
    };
};

function profitLossRequest() {
    return {
        type: shopConstants.PROFIT_LOSS_REQUEST,
    };
};

function profitLossSuccess(payload) {
    return {
        type: shopConstants.PROFIT_LOSS_SUCCESS,
        payload,
    };
};

function checkoutLocationRequest() {
    return {
        type: shopConstants.CHECKOUT_LOCATION_REQUEST,
    };
};

function checkoutLocationSuccess(payload) {
    return {
        type: shopConstants.CHECKOUT_LOCATION_SUCCESS,
        payload,
    };
};