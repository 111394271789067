import createStore from "./createStoreReducer";
import loginReducer from "./loginReducer";
import getIndustries from "./getIndustriesReducer";
import getStore from "./getStoreReducer";
import editStore from "./editStoreReducer";
import getOneStore from "./getOneStoreReducer";
import productReq from "./productRequirementReducer";
import createProduct from "./createProductReducer";
import getProducts from "./getProductsReducer";
import getOneProduct from "./getOneProductReducer";
import createSupplier from "./createSupplierReducer";
import getSupplier from "./getSupplierReducer";
import submitSupplies from "./submitSuppliesReducer";
import getSupplies from "./getSuppliesReducer";
import getWebsite from "./getWebsiteReducer";
import updateWebsite from "./updateWebsiteReducer";
import updateProduct from "./updateProductReducer";
import getUser from "./getUserReducer";
import {
  getStoreOrders,
  getOneOrder,
  changeOrderStatus,
} from "./getStoreOrdersReducer";
import accountBalance from "./accountBalanceReducer";
import { getStorePaymentConfig } from "./paymentConfigReducer";
import { getAllPaymentConfig } from "./paymentConfigReducer";
import { updateStorePaymentConfig } from "./paymentConfigReducer";
import getStoreQr from "./getStoreQrReducer";
import generateInvoice from "./generateInvoiceReducer";
import getInvoice from "./getInvoiceReducer";
import currentStore from "./currentStoreReducer";
import getOneInvoice from "./getOneInvoiceReducer";
import sendInvoiceEmail from "./sendInvoiceEmailReducer";
import orderHistory from "./getOrderHistoryReducer";
import filterOrder from "./filterOrderReducer";
import getTemplates from "./getTemplatesReducer";
import walletTransactions from "./walletTransactionReducer";
import { topItems, productViews, checkoutViews, salesReport, profitLoss, checkoutLocation } from "./reportReducer";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "current"],
};

const rootReducer = combineReducers({
  user: getUser,
  loginReducer,
  current: currentStore,
  accountBalance,
  createStore,
  getStore,
  editStore,
  getOneStore,
  getIndustries,
  productReq,
  createProduct,
  updateProduct,
  getProducts,
  getOneProduct,
  createSupplier,
  getSupplier,
  submitSupplies,
  getSupplies,
  getWebsite,
  updateWebsite,
  getStoreOrders,
  getOneOrder,
  changeOrderStatus,
  getStorePaymentConfig,
  getAllPaymentConfig,
  updateStorePaymentConfig,
  getStoreQr,
  generateInvoice,
  getInvoice,
  getOneInvoice,
  orderHistory,
  sendInvoiceEmail,
  filterOrder,
  getTemplates,
  walletTransactions,
  topItems,
  productViews,
  checkoutViews,
  salesReport,
  profitLoss,
  checkoutLocation
});

export default persistReducer(persistConfig, rootReducer);
