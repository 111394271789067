import React, { createRef, useEffect, useState } from "react";
import { Col, message, Row } from "antd";
import { connect, useSelector } from "react-redux";
import Navigation from "../components/Navigation";
import store from "../services/storeService";
import website from "../services/websiteService";
import { HeaderBar } from "../Apperance";
import "../css/shop.css";

function MyShop(props) {
  const storeId = props.store ? props.store.id : "";
  const [value, setValue] = useState(props.website.website_template);
  const current = useSelector((state) => state.current.data);

  useEffect(() => {
    props.get_one_store(current);
    props.get_templates();
  }, []);

  useEffect(() => {
    if (storeId) {
      props.get_website(storeId);
    }
  }, [storeId]);

  useEffect(() => {
    setValue(props.website.website_template);
  }, [value]);

  function changeTemplate(val) {
    setValue(val);
    props.update_website(storeId, {
      website_published: "true",
      website_template: val,
    });
  };

  return (
    <Navigation {...props}>
      <HeaderBar topic="Choose a website template" />
      <div
        className="site-layout-background"
        style={{ padding: 44, minHeight: 360 }}
      >
        <div>
          {props.website.website_template === "" ||
          props.website.website_template === undefined ? (
            <h5 className="ins" style={{ color: "red" }}>
              You have not selected any template. Click on one of the templates
              below to proceed
            </h5>
          ) : (
            <p className="ins">
              Your chosen template is{" "}
              <span>{props.website.website_template}</span>
              <h3>
                Click on any of the templates below to change your template
              </h3>
            </p>
          )}

          <Row gutter={24}>
            {props.templates.map((res, i) => {
              return (
                <Col lg={8} key={i}>
                  <div
                    onClick={() => changeTemplate(res.template_id)}
                    className={
                      res.template_id === props.website.website_template
                        ? "template-selected"
                        : "template-not-selected"
                    }
                  >
                    <img
                      src={res.template_image}
                      className="shop-img"
                      alt={res.name}
                    />
                  </div>
                  <p style={{ textAlign: "center", fontSize: "12px" }}>
                    CATEGORY: {res.template_category}
                  </p>
                  <p className="template-title">{res.name}</p>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </Navigation>
  );
}

const mapStateToProps = (state) => {
  return {
    store: state.getOneStore.data,
    website: state.getWebsite.data,
    templates: state.getTemplates.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_one_store: (id) => dispatch(store.get_one_store(id)),
    get_website: (storeId) => dispatch(website.get_website(storeId)),
    update_website: (storeId, data) =>
      dispatch(website.update_website(storeId, data)),
    get_templates: () => dispatch(website.get_templates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyShop);
