
import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';

const baseUrl = `${config.apiUrl}`;

const qr = {
    // To list a store's payment configurations
    get_store_qr: (user_id) => {
        return async dispatch => {
            dispatch(shopActions.getStoreQrRequest());

            await axios.get(`${baseUrl}/qr/merchants_and_sub_merchants_user/${user_id}`)
                .then((res) => {
                    dispatch(shopActions.getStoreQrSuccess(res.data.data));
                });
        }
    }

}
export default qr;