import './App.css';
import 'antd/dist/antd.css';
import Inventory from './pages/Inventory/Inventory';
import { Router, Switch, Route } from "react-router-dom";
import { history } from './store/history';
import Overview from './pages/Overview';
import AddProduct from './pages/Inventory/AddProduct';
import CreateStore from './pages/Store/CreateStore';
import EditProduct from './pages/Inventory/EditProduct';
import MyShop from './pages/MyShop';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import auth from './services/authService';
import Orders from './pages/Orders/Orders';
import { Settings } from './pages/Settings';
import GenerateInvoice from './pages/Invoice/GenerateInvoice';
import { OrderDetails } from './pages/Orders/OrderDetails';
import Invoice from './pages/Invoice/Invoice';
import { InvoiceDetails } from './pages/Invoice/InvoiceDetails';
import { Store } from './pages/Store/Store';
import Report from './pages/Report/Report';
const CrossStorage = require('cross-storage-plus');

function App() {

  let id;
  let store;
  const dispatch = useDispatch();
  async function storage() {
    store = await CrossStorage.initializeClient('http://getripay.lending.ng/index.html');
    const user_id = await store.getItem('user_id')
    id = user_id;
    if (id) {
      dispatch(auth.get_user(id))
    }
  };

  useEffect(() => {
    storage()
  }, []);

  return (
    // <ThemeContext.Provider value={theme}>
    <Router history={history} >
      <Switch>
        <Route path="/" exact component={Store} />
        <Route path="/inventory" exact component={Inventory} />
        <Route path="/overview" exact component={Overview} />
        <Route path="/add-product" exact component={AddProduct} />
        <Route path="/create-store" exact component={CreateStore} />
        <Route path="/edit-product/:slug" exact component={EditProduct} />
        <Route path="/my-shop" exact component={MyShop} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/settings" exact component={Settings} />
        <Route path="/invoice" exact component={Invoice} />
        <Route path="/generate-invoice" exact component={GenerateInvoice} />
        <Route path="/invoice-details/:slug" exact component={InvoiceDetails} />
        <Route path="/order-details/:slug" exact component={OrderDetails} />
        <Route path="/report" exact component={Report} />
      </Switch>
    </Router>
    // </ThemeContext.Provider >
  );
}

export default App;
