import React, { useState } from 'react';
import { Layout } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import TopicMenu from './TopicMenu';


const { Sider, Content } = Layout;

export default function Navigation(props) {
    const [collapsed, setCollapsed] = useState(false);

    const onCollapse = tab => {
        setCollapsed(tab);
    };
    const user = (useSelector(state => state.user.data))

    const topics = [
        {
            top: "New Store ",
            route: '/create-store',
            icon: <PieChartOutlined />,
            className: '.create'
        },
        {
            top: "Overview",
            route: '/overview',
            icon: <PieChartOutlined />,
            className: '.overview'
        },
        {
            top: "My shop",
            route: '/my-shop',
            icon: <PieChartOutlined />,
            className: '.shop'
        },
        {
            top: "Inventory",
            route: '/inventory',
            icon: <PieChartOutlined />,
            className: '.inventory'
        },

        {
            top: "Orders",
            route: '/orders',
            icon: <PieChartOutlined />,
            className: '.orders'
        },

        {
            top: "Invoice",
            route: '/invoice',
            icon: <PieChartOutlined />,
            className: '.invoice'
        },
        {
            top: "Report",
            route: '/report',
            icon: <PieChartOutlined />,
            className: '.report'
        },
    ]
    const [contentIndex, setContentIndex] = useState(0);
    const [selectedKey, setSelectedKey] = useState("0");
    const changeSelectedKey = (event) => {
        const key = event.key;
        setSelectedKey(key);
        setContentIndex(+key);
    };



    const NewMenu = () => {
        return (
            <TopicMenu
                topics={topics}
                selectedKey={selectedKey}
                changeSelectedKey={changeSelectedKey}
                history={props.history}
            />
        )
    };

    return (
        <>
            <NavBar menu={<NewMenu />} user={user} />
            <Layout >
                <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}
                    className="sidebar"
                    breakpoint={"lg"}
                    theme="light"
                    collapsedWidth={0}
                    trigger={null}
                >
                    <NewMenu />
                </Sider>

                <Content >
                    {props.children}
                </Content>
            </Layout>
        </>

    );
}
