import { shopActions } from "../actions/creators";
import config from "../config";
import axios from "axios";
import swal from "sweetalert";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const report = {
  // To get a user's shop website

  top_items: (storeId) => {
    return async (dispatch) => {
      dispatch(shopActions.topSellingItemsRequest());

      await axios
        .get(`${baseUrl}/ecommerce/store/top/selling/products/${storeId}`)
        .then((res) => {
          dispatch(shopActions.topSellingItemsSuccess(res.data.data));
        });
    };
  },

  product_views: (storeId) => {
    return async (dispatch) => {
      dispatch(shopActions.productViewsRequest());
      await axios
        .get(`${baseUrl}/ecommerce/store/all/product/views/${storeId}`)
        .then((res) => {
          dispatch(shopActions.productViewsSuccess(res.data.data));
        });
    };
  },

  checkout_views: (storeId) => {
    return async (dispatch) => {
      dispatch(shopActions.checkoutViewsRequest());
      await axios
        .get(`${baseUrl}/ecommerce/store/checkout/product/views/${storeId}`)
        .then((res) => {
          dispatch(shopActions.checkoutViewsSuccess(res.data.data));
        });
    };
  },

  sales_report: (storeId) => {
    return async (dispatch) => {
      dispatch(shopActions.salesReportRequest());
      await axios
        .get(`${baseUrl}/ecommerce/sales/report/${storeId}`)
        .then((res) => {
          dispatch(shopActions.salesReportSuccess(res.data.data));
        });
    };
  },

  profit_loss: (storeId) => {
    return async (dispatch) => {
      dispatch(shopActions.profitLossRequest());
      await axios
        .get(`${baseUrl}/ecommerce/profit/loss/${storeId}`)
        .then((res) => {
          dispatch(shopActions.profitLossSuccess(res.data.data));
        });
    };
  },

  
  checkout_location: (storeId) => {
    return async (dispatch) => {
      dispatch(shopActions.checkoutLocationRequest());
      await axios
        .get(`${baseUrl}/ecommerce/store/product/checkout/locations/${storeId}`)
        .then((res) => {
          dispatch(shopActions.checkoutLocationSuccess(res.data.data));
        });
    };
  },
};
export default report;
