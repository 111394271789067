import { Button, Input, Form, Col, Row, Select, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation';
import { connect, useSelector } from 'react-redux';
import store from '../../services/storeService';
import { IKContext, IKUpload } from 'imagekitio-react';
import { HeaderBar } from '../../Apperance';
import { Option } from 'antd/lib/mentions';
import { states } from './states';


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const publicKey = 'public_sYCPM1kMSIovPWZMi7Mj83OMCBA=';
const authenticationEndpoint = 'https://api.test.getripay.com/api/v1/ecommerce/auth_params';
const urlEndpoint = 'https://ik.imagekit.io/rnh8t89owod';


function CreateStore(props) {
    const [form] = Form.useForm();
    const [url, setUrl] = useState('')
    const user = useSelector(state => state.user.data);
    const [regexError, setRegexError] = useState(false);
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    useEffect(() => {
        props.get_industries();
    }, []);

    const onError = err => {
        console.log("Error", err);
    };

    const onSuccess = res => {
        console.log("Success", res);
        setUrl(res.url)
    };

    const onFinish = (values) => {
        const data = {
            storeName: values.storeName,
            storeDescription: values.storeDescription,
            userId: user.id,
            storeImageUrl: url,
            industryId: values.industryId,
            preferredUrl: values.preferredUrl,
            isOffline: false,
            shippingLocations: values.shippingLocations && (values.shippingLocations).toString()
        }
        console.log(data)
        props.create_store(data)
    };

    function regexChecker(val) {
        const regex = /^([a-zA-Z])[a-zA-Z0-9-_]*$/i
        const response = val.target.value.match(regex)
        if (response === null) {
            setRegexError(true)
        } else {
            setRegexError(false)
        }
    }


    return (
        <Navigation {...props}>
            <HeaderBar topic="Create a Store" />
            <Row className='create-store-page'>
                <Col span={24} lg={12} sm={24} xs={24}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Row>
                            <Col lg={4} sm={24} xs={24}></Col>
                            <Col lg={16} sm={24} xs={24}>
                                <div className='create-store-box' >
                                    <Form
                                        {...formItemLayout}
                                        form={form}
                                        name="register"
                                        onFinish={onFinish}
                                        scrollToFirstError >
                                        <Form.Item
                                            style={{ width: '100%' }}
                                            name="storeName"
                                            rules={[{ required: true, message: 'Please enter your store name' }]}>
                                            <Input placeholder='Store Name' />
                                        </Form.Item>

                                        <Form.Item
                                            name="storeDescription"
                                            rules={[{ required: true, message: 'Please enter your store description', whitespace: true }]} >
                                            <Input placeholder='Store Description' />
                                        </Form.Item>

                                        <Form.Item
                                            name="preferredUrl"
                                            rules={[{ required: true, message: 'Please enter your store domain name', whitespace: true }]} >
                                            <Input placeholder='Store Domain Name' onBlur={regexChecker} />
                                        </Form.Item>
                                        {regexError && <p style={{ color: 'red', marginTop: "-15px", fontSize: '12px' }}>You are only allowed to use - and _ in your domain name. Must also not include domain name</p>}

                                        <Form.Item name="industryId" rules={[{ required: true, message: 'Please select an industry' }]} >
                                            <Select className='select-create' style={{ width: '350px' }} placeholder="Please select an industry for your store">
                                                {props.industries && props.industries.map((res, i) => {
                                                    return (
                                                        <Option value={res.id}>{res.industryName}</Option>
                                                    )
                                                })

                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name='shippingLocations' rules={[{ required: true, message: 'Please select an shipping location' }]}>
                                            <Radio.Group onChange={onChange} value={value}>
                                                <Radio value='Worldwide'>Ship Worldwide</Radio>
                                                <Radio value='Nigeria'>Within Nigeria</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        {value === 'Nigeria' ?
                                            <Form.Item name='shippingLocations'>
                                                <Select mode='multiple' placeholder='Select the state(s) you ship to' >
                                                    {states.map((res, i) => {
                                                        return (
                                                            <Option key={i} value={res}>{res}</Option>
                                                        )
                                                    })}

                                                </Select>
                                            </Form.Item>

                                            :
                                            null
                                        }


                                        <IKContext
                                            urlEndpoint={urlEndpoint}
                                            publicKey={publicKey}
                                            authenticationEndpoint={authenticationEndpoint}
                                        >
                                            <p>Upload an image</p>
                                            <IKUpload
                                                fileName="ecommerce.png"
                                                onError={onError}
                                                onSuccess={onSuccess}
                                            />
                                        </IKContext>

                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <Form.Item >
                                                <Button disabled={regexError === true} type="primary" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </div>

                                    </Form>
                                </div>
                            </Col>
                            <Col lg={4} sm={24} xs={24}></Col>
                        </Row>

                    </div>
                </Col>
                <Col span={24} lg={12} style={{ backgroundColor: '#f6f6f7', height: '100vh' }}>
                    {
                        props.store === true ?
                            <p style={{ margin: 'auto', textAlign: 'center' }}>Store created successfully</p>
                            :
                            ''
                    }
                </Col>
            </Row>

        </Navigation>
    )
}

const mapStateToProps = (state) => {
    return {
        store: state.createStore.data,
        industries: state.getIndustries.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        create_store: (data) => dispatch(store.create_store(data)),
        get_industries: () => dispatch(store.get_industries()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStore)