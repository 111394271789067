import React, { useEffect, useState } from 'react';
import '../../css/add-product.css'
import { Button, Form, Input, Select, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import product_requirements from '../../services/productRequirementService';
import { connect } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import product from '../../services/productService';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

function StepTwo(props) {
    const [form] = Form.useForm();
    const [val, setVal] = useState('');
    const [inside, setInside] = useState('')

    const getProductId = JSON.parse(localStorage.getItem('current'))

    const [pro, setPro] = useState({
        productId: getProductId.id,
        propertyId: '',
        propertyValue: ''
    })

    const onFinish = (values) => {
        console.log('hi', values)
    };

    const handleInside = (e) => {
        setInside(e.target.value)
    }

    const handleChange = (values) => {
        setVal(values)
        setInside('')
    };

    useEffect(() => {
        props.get_properties()
    }, []);

    useEffect(() => {
        setPro({
            productId: pro.productId,
            propertyId: val,
            propertyValue: inside
        })
    }, [val, inside])


    function submit() {
        props.submit_properties(pro)
    }

    return (


        <Form
            style={{ marginTop: '50px' }}
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError >
            <div>
                <Form.Item >
                    <Select placeholder="Please select a property type" onChange={handleChange}>
                        {props.properties.map((x, i) => {
                            return (
                                <>
                                    <Option value={x.id} >{x.propertyName}</Option>


                                </>


                            )
                        })

                        }




                    </Select>
                    {val &&
                        <Form.Item value={inside} >
                            <Input onChange={handleInside} />
                            <Button type="primary" style={{ marginTop: '10px' }} onClick={submit}>Submit property</Button>
                        </Form.Item>
                    }
                </Form.Item>

            </div>

            <div style={{ textAlign: 'center' }}>
                <Button type="primary" onClick={props.next}>
                    Next
                </Button>
            </div>
        </Form>
    )
}

const mapStateToProps = (state) => {
    return {
        properties: state.productReq.properties,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_properties: () => dispatch(product_requirements.get_properties()),
        submit_properties: (pro) => dispatch(product.submit_properties(pro)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo)