
import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';

const baseUrl = `${config.apiUrl}`;

const orders = {
    // To get a user's shop website

    get_store_orders: (storeId) => {
        return async dispatch => {
            dispatch(shopActions.getStoreOrdersRequest());

            await axios.get(`${baseUrl}/ecommerce/store_orders/${storeId}?page=0`)
                .then((res) => {
                    dispatch(shopActions.getStoreOrdersSuccess(res.data.data));
                });
        }
    },


    get_store_orders_pagination: (storeId, page) => {
        return async dispatch => {
            dispatch(shopActions.getStoreOrdersRequest());

            await axios.get(`${baseUrl}/ecommerce/store_orders/${storeId}?page=${page}`)
                .then((res) => {
                    dispatch(shopActions.getStoreOrdersSuccess(res.data.data));
                });
        }
    },

    get_one_order: (orderId) => {
        return async dispatch => {
            dispatch(shopActions.getOneOrderRequest());

            await axios.get(`${baseUrl}/ecommerce/order/${orderId}`)
                .then((res) => {
                    dispatch(shopActions.getOneOrderSuccess(res.data.data));
                });
        }
    },

    change_order_status: (data) => {
        const orderStatusId = data.orderStatusId;
        return async dispatch => {
            dispatch(shopActions.changeOrderStatusRequest());

            await axios.put(`${baseUrl}/ecommerce/orders/change_status/${data.orderId}`, { orderStatusId })
                .then((res) => {
                    dispatch(shopActions.changeOrderStatusSuccess(res.data.data));
                    alert('order status changed successfully')
                    setTimeout(() => {
                        window.location.reload()
                    }, 200)
                });
        }
    },

    remove_order_item: (itemId) => {
        return async dispatch => {
            dispatch(shopActions.changeOrderStatusRequest());

            await axios.put(`${baseUrl}/ecommerce/order/item/remove/${itemId}`)
                .then((res) => {
                    dispatch(shopActions.changeOrderStatusSuccess(res.data.data));
                    alert('order item removed successfully')
                    setTimeout(() => {
                        window.location.reload()
                    }, 200)
                });
        }
    },

    get_order_history: (orderId) => {
        return async dispatch => {
            dispatch(shopActions.getOrderHistoryRequest());

            await axios.get(`${baseUrl}/ecommerce/history/order/status/${orderId}`)
                .then((res) => {
                    dispatch(shopActions.getOrderHistorySuccess(res.data.data));
                });
        }
    },

    filter_order: (storeId, statusId) => {
        return async dispatch => {
            dispatch(shopActions.filterOrderRequest());

            await axios.get(`${baseUrl}/ecommerce/store_orders/${storeId}?page=0&&orderStatusId=${statusId}`)
                .then((res) => {
                    dispatch(shopActions.filterOrderSuccess(res.data.data));
                });
        }
    },
}
export default orders;