import { shopConstants } from "../actions/types";

const initialState = {
  isLoading: false,
  topItems: [],
  productViews: [],
  checkoutViews: [],
  salesReport: [],
  profitLoss: [],
  checkoutLocation: [],
};

function topItems(state = initialState, action) {
  switch (action.type) {
    case shopConstants.TOP_SELLING_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case shopConstants.TOP_SELLING_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        topItems: action.payload,
      };
    default:
      return state;
  }
}

function productViews(state = initialState, action) {
  switch (action.type) {
    case shopConstants.PRODUCT_VIEWS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case shopConstants.PRODUCT_VIEWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productViews: action.payload,
      };
    default:
      return state;
  }
}

function checkoutViews(state = initialState, action) {
  switch (action.type) {
    case shopConstants.CHECKOUT_VIEWS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case shopConstants.CHECKOUT_VIEWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkoutViews: action.payload,
      };
    default:
      return state;
  }
}

function salesReport(state = initialState, action) {
  switch (action.type) {
    case shopConstants.SALES_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case shopConstants.SALES_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        salesReport: action.payload,
      };
    default:
      return state;
  }
}

function profitLoss(state = initialState, action) {
  switch (action.type) {
    case shopConstants.PROFIT_LOSS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case shopConstants.PROFIT_LOSS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profitLoss: action.payload,
      };
    default:
      return state;
  }
}

function checkoutLocation(state = initialState, action) {
  switch (action.type) {
    case shopConstants.CHECKOUT_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case shopConstants.CHECKOUT_LOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkoutLocation: action.payload,
      };
    default:
      return state;
  }
}

export {
  topItems,
  productViews,
  checkoutViews,
  salesReport,
  profitLoss,
  checkoutLocation,
};
