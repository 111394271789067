import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';
import { history } from "../store/history";
import { message } from "antd";

const baseUrl = `${config.apiUrl}`;

const supplier = {
    // To get product types
    create_supplier: (supplier) => {
        return async dispatch => {
            dispatch(shopActions.createSupplierRequest());

            await axios.post(`${baseUrl}/ecommerce/supliers`, supplier)
                .then((res) => {
                    dispatch(shopActions.createSupplierSuccess(res.data));

                });
        }
    },

    get_supplier: (storeId) => {
        return async dispatch => {
            dispatch(shopActions.getSupplierRequest());

            await axios.get(`${baseUrl}/ecommerce/supliers/${storeId}`)
                .then((res) => {
                    dispatch(shopActions.getSupplierSuccess(res.data.data));
                });
        }
    },

   submit_supplies: (supplies) => {
        return async dispatch => {
            dispatch(shopActions.submitSuppliesRequest());

            await axios.post(`${baseUrl}/ecommerce/suplies`, supplies)
                .then((res) => {
                    dispatch(shopActions.submitSuppliesSuccess(res.data));

                });
        }
    },

    get_supplies: (productId) => {
        return async dispatch => {
            dispatch(shopActions.getSuppliesRequest());

            await axios.get(`${baseUrl}/ecommerce/suplies/${productId}`)
                .then((res) => {
                    dispatch(shopActions.getSuppliesSuccess(res.data.data));
                });
        }
    },
}
export default supplier;