import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Input, Row, Col } from "antd";

function DynamicField() {
  return (
    <Form.List name="items">
      {(items, { add, remove }) => {
        return (
          <div>
            {items.map((field, index) => (
              <div key={field.key}>
                <Divider>Item {index + 1}</Divider>
                <Row>
                  <Col lg={4} sm={8} xs={8} style={{margin:'15px'}}>
                    <p>Name</p>
                    <Form.Item
                      name={[index, "item"]}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Item name" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={8} xs={8} style={{margin:'15px'}}>
                    <p>Description</p>
                    <Form.Item
                      name={[index, "description"]}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={8} xs={8} style={{margin:'15px'}}>
                    <p>Quantity</p>
                    <Form.Item
                      name={[index, "quantity"]}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Quantity" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={8} xs={8} style={{margin:'15px'}}>
                    <p>Price</p>
                    <Form.Item
                      name={[index, "price"]}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Price" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={8} xs={8} style={{margin:'15px'}}>
                    <p>Tax</p>
                    <Form.Item
                      name={[index, "tax"]}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Tax" />
                    </Form.Item>
                  </Col>
                </Row>



                {items.length > 1 ? (
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}

                  >
                    Remove Above Item
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
            <Form.Item style={{ margin: '10px' }}>
              <Button
                type="primary"
                onClick={() => add()}
              >
                <PlusOutlined /> Add new Item
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default DynamicField;
