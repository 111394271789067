import React from "react";
import { Table } from 'ant-table-extensions';
import { viewsColumns} from "../../components/data";

export const ProductViews = (props) => {
  const dataSource =
    props.views &&
    props.views.map((pro, i) => {
      return {
        key: i,
        name: pro.productId,
        count: pro.count,
      };
    });

  return (
    <div>
      <Table
        scroll={{ x: 1400, y: 600 }}
        dataSource={dataSource}
        columns={viewsColumns}
        searchable
        // onChange={onChange}
        sticky
      />
    </div>
  );
};
