import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';
import { history } from "../store/history";
import swal from 'sweetalert';

const baseUrl = `${config.apiUrl}`;

const product = {
    // To get product types
    create_product: (products) => {
        return async dispatch => {
            dispatch(shopActions.createProductRequest());

            await axios.post(`${baseUrl}/ecommerce/products`, products)
                .then((res) => {
                    dispatch(shopActions.createProductSuccess(res.data));
                    swal("Success!", 'Product successfully created. Please move to the next step', "success");
                    localStorage.setItem('current', JSON.stringify(res.data.data))
                });
        }
    },

    get_products: (storeId) => {
        return async dispatch => {
            dispatch(shopActions.getProductsRequest());

            await axios.get(`${baseUrl}/ecommerce/store_products/${storeId}?page=0`)
                .then((res) => {
                    dispatch(shopActions.getProductsSuccess(res.data.data));
                });
        }
    },

    get_products_pagination: (storeId, page) => {
        return async dispatch => {
            dispatch(shopActions.getProductsRequest());

            await axios.get(`${baseUrl}/ecommerce/store_products/${storeId}?page=${page}`)
                .then((res) => {
                    dispatch(shopActions.getProductsSuccess(res.data.data));
                });
        }
    },

    get_one_product: (id) => {
        return async dispatch => {
            dispatch(shopActions.getOneProductRequest());

            await axios.get(`${baseUrl}/ecommerce/product/${id}`)
                .then((res) => {
                    dispatch(shopActions.getOneProductSuccess(res.data.data));
                });
        }
    },


    submit_properties: (pro) => {
        return async dispatch => {
            // dispatch(shopActions.createProductRequest());

            await axios.post(`${baseUrl}/ecommerce/product_properties`, pro)
                .then((res) => {
                    // dispatch(shopActions.createProductSuccess(res.data));
                    swal("Success!", 'Property Submittted successfully. You can add more proerties or click next to move to the next step', "success");
                });
        }
    },

    update_product: (data) => {
        const productImagesUrl = data.productImagesUrl;
        return async dispatch => {
            await axios.put(`${baseUrl}/ecommerce/product/${data.productId}`, { productImagesUrl })
                .then((res) => {
                    swal("Success!", 'Image uploaded successfully', "success");
                    history.push('/inventory')
                });
        }
    },

    update_tag: (id, tag) => {
        const productTags = tag
        return async dispatch => {
            dispatch(shopActions.updateProductRequest());
            await axios.put(`${baseUrl}/ecommerce/product/${id}`, { productTags })
                .then((res) => {
                    dispatch(shopActions.updateProductSuccess());
                });
        }
    },

    update_price: (data) => {
        return async dispatch => {
            dispatch(shopActions.updateProductRequest());
            await axios.post(`${baseUrl}/ecommerce/product/prices`, data)
                .then((res) => {
                    dispatch(shopActions.updateProductSuccess());
                });
        }
    },
}
export default product;