import React, { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation';
import '../../css/orders.css';
import { connect, useSelector } from 'react-redux';
import store from '../../services/storeService';
import orders from '../../services/orderService';
import { Col, Row, Pagination, Tabs, Collapse, Skeleton } from 'antd';
import { DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { AcceptModal } from '../../components/Orders/AcceptModal';
import { DeclineModal } from '../../components/Orders/DeclineOrder';
import { history } from '../../store/history';
import { RemoveItemModal } from '../../components/Orders/RemoveItemModal';

const { Panel } = Collapse;
const { TabPane } = Tabs;


const style = {
    textAlign: 'center'
}

function Orders(props) {
    const [accept, setAccept] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [remove, setRemove] = useState(false);
    const [id, setId] = useState('');
    const [itemId, setItemId] = useState('');
    const user = useSelector(state => state.user.data)
    const storeId = props.store ? props.store.id : '';
    const current = useSelector(state => state.current.data);

    const filter = useSelector(state => state.filterOrder.data)
    const loading = useSelector(state => state.filterOrder.isLoading)

    function callback(key) {
        props.filter_order(storeId, key)
    }

    useEffect(() => {
        if (storeId) {
            props.get_store_orders(storeId)
        }
    }, [storeId]);

    useEffect(() => {
        if (user.id) {
            props.get_one_store(current)
        }
    }, []);

    const showAccept = (id) => {
        setAccept(true);
        setId(id)
    };
    const handleCloseAccept = () => {
        setAccept(false);
    };

    const showCancel = (id) => {
        setCancel(true);
        setId(id)
    };
    const handleCloseCancel = () => {
        setCancel(false);
    };

    const showRemove = (item) => {
        setRemove(true);
        setItemId(item)
    };
    const handleCloseRemove = () => {
        setRemove(false);
    };


    function onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
        console.log(pagination)
        props.get_store_orders_pagination(current, pagination)
    }

    return (
        <>
            <Navigation {...props}>
                <h4 className='page-title'>My Orders</h4>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <Tabs defaultActiveKey="0" onChange={callback}>
                        <TabPane tab="All Orders" key="0">
                            <div style={{ textAlign: "right", marginBottom: '20px' }}>
                                <Pagination
                                    pageSize={10}
                                    total={props.orders.totalItems}
                                    current={props.orders.currentPage}
                                    defaultCurrent={0} total={50} onChange={onChange} />
                            </div>

                            {props.orders.orders && props.orders.orders.map((data, i) => {
                                return (
                                    <div >

                                        <Row className='order-summary'>
                                            <Col lg={12} sm={12} xs={12}>
                                                <Row>
                                                    <Col lg={6} sm={9} xs={9}>
                                                        <p>Order ID:  </p>
                                                        <p>Date:</p>
                                                        <p>Total</p>
                                                    </Col>
                                                    <Col lg={8} sm={15} xs={15}>
                                                        <p className='order-sub'>{data.orderNumber}</p>
                                                        <p className='order-sub'>{new Date(data.createdAt).toLocaleString('en-CA')}</p>
                                                        <p className='order-sub'>NGN {(data.orderSum.toLocaleString())}</p>
                                                    </Col>
                                                </Row>


                                            </Col>
                                            <Col lg={6} sm={6} xs={6}>
                                                <Row>
                                                    <Col lg={4} sm={12} xs={12}>
                                                        {/* <p>Buyer</p> */}
                                                        <p onClick={() => history.push(`order-details/${data.id}`)} style={{ cursor: "pointer", fontWeight: 'bolder', cursor: "pointer", color: 'blue' }}>Details</p>
                                                    </Col>
                                                    {/* <Col lg={4} sm={12} xs={12}>
                                                        <p className='order-sub'>Abimbola</p>
                                                    </Col> */}
                                                </Row>

                                            </Col>


                                            {data.orderStatus && (
                                                <Col lg={6} sm={6} xs={6}>
                                                    { data.orderStatus.id === 1 ?
                                                        <>
                                                            <div onClick={() => showAccept(data.id)} style={{ cursor: "pointer", marginBottom: '10px', fontWeight: "bolder", color: 'green' }} ><CheckCircleOutlined /> Accept Order</div>
                                                            <div onClick={() => showCancel(data.id)} style={{ cursor: "pointer", fontWeight: "bolder", color: 'red' }} ><DeleteOutlined style={{ fontSize: '20px', color: 'red' }} /> Decline Order</div>
                                                        </>
                                                        :
                                                        data.orderStatus.id === 2 ?
                                                            <p>Order Accepted</p>
                                                            :
                                                            data.orderStatus.id === 3 ?
                                                                <p>Order declined</p>
                                                                :
                                                                data.orderStatus.id === 4 ?
                                                                    <p>Order Complete</p>
                                                                    :
                                                                    ''
                                                    }

                                                </Col>

                                            )
                                            }
                                            <Col lg={12}>
                                                <Collapse accordion defaultActiveKey={['0']} >
                                                    <Panel header="Order Summary" key={i} >
                                                        <div style={{ overflowX: 'auto' }}>
                                                            <table style={{ fontSize: '12px' }}>
                                                                <tr >
                                                                    <th style={style}>Product Name</th>
                                                                    <th style={style}>ID</th>
                                                                    <th style={style}>Price</th>
                                                                    <th style={style}>Quantity</th>
                                                                    {/* <th style={style}>Remove Item</th> */}
                                                                </tr>
                                                                {data.orderDetails.map((det, i) => {
                                                                    return (
                                                                        <>
                                                                            {det.Product && det.Product.productPrice &&
                                                                                <tr key={i}>
                                                                                    <td style={{ width: '30%', padding: '5px 0px 5px 20px' }}>
                                                                                        <img src={det.Product.productImagesUrl} width='80' alt='order' />
                                                                                        {det.Product.productName}
                                                                                    </td>
                                                                                    <td style={style}>{det.itemNumber}</td>
                                                                                    <td style={style}>{Number(det.Product.productPrice).toLocaleString()}</td>
                                                                                    <td style={style}>{det.quantity}</td>
                                                                                    {/* {det.deletedAt === null && (
                                                                                    <td style={style}><CloseCircleOutlined onClick={() => showRemove(det.id)} style={{ fontSize: '20px', color: 'red' }} /> Remove</td>
                                                                                )

                                                                                } */}

                                                                                </tr>
                                                                            }

                                                                        </>
                                                                    )
                                                                })

                                                                }
                                                            </table>
                                                        </div>

                                                    </Panel>

                                                </Collapse>
                                            </Col>

                                        </Row>

                                        <AcceptModal accept={accept} cancel={handleCloseAccept} id={id} />
                                        <DeclineModal decline={cancel} cancel={handleCloseCancel} id={id} />
                                        {/* <RemoveItemModal remove={remove} cancel={handleCloseRemove} itemId={itemId} /> */}
                                    </div>
                                )
                            })

                            }
                        </TabPane>


                        <TabPane tab="Pending Orders" key="1">
                            {/* <div style={{ textAlign: "right", marginBottom: '20px' }}>
                                <Pagination
                                    pageSize={10}
                                    total={props.orders.totalItems}
                                    current={props.orders.currentPage}
                                    defaultCurrent={0} total={50} onChange={onChange} />
                            </div> */}
                            {loading ?
                                (<Skeleton active />)
                                :
                                <>

                                    {filter.orders && filter.orders.map((data, i) => {
                                        return (
                                            <div >

                                                <Row className='order-summary'>
                                                    <Col lg={12} sm={12} xs={12}>
                                                        <Row>
                                                            <Col lg={6} sm={9} xs={9}>
                                                                <p>Order ID:  </p>
                                                                <p>Date:</p>
                                                                <p>Total</p>
                                                            </Col>
                                                            <Col lg={8} sm={15} xs={15}>
                                                                <p className='order-sub'>{data.orderNumber}</p>
                                                                <p className='order-sub'>{new Date(data.createdAt).toLocaleString('en-CA')}</p>
                                                                <p className='order-sub'>NGN {(data.orderSum.toLocaleString())}</p>
                                                            </Col>
                                                        </Row>


                                                    </Col>
                                                    <Col lg={6} sm={6} xs={6}>
                                                        <Row>
                                                            <Col lg={4} sm={12} xs={12}>
                                                                {/* <p>Buyer</p> */}
                                                                <p onClick={() => history.push(`order-details/${data.id}`)} style={{ cursor: "pointer", fontWeight: 'bolder', cursor: "pointer", color: 'blue' }}>Details</p>
                                                            </Col>
                                                            {/* <Col lg={4} sm={12} xs={12}>
                                                        <p className='order-sub'>Abimbola</p>
                                                    </Col> */}
                                                        </Row>

                                                    </Col>


                                                    {data.orderStatus && (
                                                        <Col lg={6} sm={6} xs={6}>
                                                            { data.orderStatus.id === 1 ?
                                                                <>
                                                                    <div onClick={() => showAccept(data.id)} style={{ cursor: "pointer", marginBottom: '10px', fontWeight: "bolder", color: 'green' }} ><CheckCircleOutlined /> Accept Order</div>
                                                                    <div onClick={() => showCancel(data.id)} style={{ cursor: "pointer", fontWeight: "bolder", color: 'red' }} ><DeleteOutlined style={{ fontSize: '20px', color: 'red' }} /> Decline Order</div>
                                                                </>
                                                                :
                                                                data.orderStatus.id === 2 ?
                                                                    <p>Order Accepted</p>
                                                                    :
                                                                    data.orderStatus.id === 3 ?
                                                                        <p>Order declined</p>
                                                                        :
                                                                        data.orderStatus.id === 4 ?
                                                                            <p>Order Complete</p>
                                                                            :
                                                                            ''
                                                            }

                                                        </Col>

                                                    )
                                                    }
                                                    <Col lg={12}>
                                                        <Collapse accordion defaultActiveKey={['0']} >
                                                            <Panel header="Order Summary" key={i} >
                                                                <div style={{ overflowX: 'auto' }}>
                                                                    <table style={{ fontSize: '12px' }}>
                                                                        <tr >
                                                                            <th style={style}>Product Name</th>
                                                                            <th style={style}>ID</th>
                                                                            <th style={style}>Price</th>
                                                                            <th style={style}>Quantity</th>
                                                                            {/* <th style={style}>Remove Item</th> */}
                                                                        </tr>
                                                                        {data.orderDetails.map((det, i) => {
                                                                            return (
                                                                                <>
                                                                                    {det.Product && det.Product.productPrice &&
                                                                                        <tr key={i}>
                                                                                            <td style={{ width: '30%', padding: '5px 0px 5px 20px' }}>
                                                                                                <img src={det.Product.productImagesUrl} width='80' alt='order' />
                                                                                                {det.Product.productName}
                                                                                            </td>
                                                                                            <td style={style}>{det.itemNumber}</td>
                                                                                            <td style={style}>{Number(det.Product.productPrice).toLocaleString()}</td>
                                                                                            <td style={style}>{det.quantity}</td>
                                                                                            {/* {det.deletedAt === null && (
                                                                                    <td style={style}><CloseCircleOutlined onClick={() => showRemove(det.id)} style={{ fontSize: '20px', color: 'red' }} /> Remove</td>
                                                                                )

                                                                                } */}

                                                                                        </tr>
                                                                                    }

                                                                                </>
                                                                            )
                                                                        })

                                                                        }
                                                                    </table>
                                                                </div>

                                                            </Panel>

                                                        </Collapse>
                                                    </Col>

                                                </Row>

                                                <AcceptModal accept={accept} cancel={handleCloseAccept} id={id} />
                                                <DeclineModal decline={cancel} cancel={handleCloseCancel} id={id} />
                                                {/* <RemoveItemModal remove={remove} cancel={handleCloseRemove} itemId={itemId} /> */}
                                            </div>
                                        )
                                    })

                                    }

                                </>
                            }
                        </TabPane>

                        <TabPane tab="Accepted Orders" key="2" >
                            {loading ? (<Skeleton active />)
                                :
                                (<>
                                    {filter.orders && filter.orders.map((data, i) => {
                                        return (
                                            <div >

                                                <Row className='order-summary'>
                                                    <Col lg={12} sm={12} xs={12}>
                                                        <Row>
                                                            <Col lg={6} sm={9} xs={9}>
                                                                <p>Order ID:  </p>
                                                                <p>Date:</p>
                                                                <p>Total</p>
                                                            </Col>
                                                            <Col lg={8} sm={15} xs={15}>
                                                                <p className='order-sub'>{data.orderNumber}</p>
                                                                <p className='order-sub'>{new Date(data.createdAt).toLocaleString('en-CA')}</p>
                                                                <p className='order-sub'>NGN {(data.orderSum.toLocaleString())}</p>
                                                            </Col>
                                                        </Row>


                                                    </Col>
                                                    <Col lg={6} sm={6} xs={6}>
                                                        <Row>
                                                            <Col lg={4} sm={12} xs={12}>
                                                                {/* <p>Buyer</p> */}
                                                                <p onClick={() => history.push(`order-details/${data.id}`)} style={{ cursor: "pointer", fontWeight: 'bolder', cursor: "pointer", color: 'blue' }}>Details</p>
                                                            </Col>
                                                            {/* <Col lg={4} sm={12} xs={12}>
                                                                <p className='order-sub'>Abimbola</p>
                                                            </Col> */}
                                                        </Row>
                                                    </Col>

                                                    <Col lg={6} sm={6} xs={6}>
                                                        <p>Order Accepted</p>

                                                    </Col>

                                                    <Col lg={12}>
                                                        <Collapse accordion defaultActiveKey={['0']} >
                                                            <Panel header="Order Summary" key={i} >
                                                                <div style={{ overflowX: 'auto' }}>
                                                                    <table style={{ fontSize: '12px' }}>
                                                                        <tr >
                                                                            <th style={style}>Product Name</th>
                                                                            <th style={style}>ID</th>
                                                                            <th style={style}>Price</th>
                                                                            <th style={style}>Quantity</th>
                                                                            {/* <th style={style}>Remove Item</th> */}
                                                                        </tr>
                                                                        {data.orderDetails.map((det, i) => {
                                                                            return (
                                                                                <>
                                                                                    {det.Product && det.Product.productPrice &&
                                                                                        <tr key={i}>
                                                                                            <td style={{ width: '30%', padding: '5px 0px 5px 20px' }}>
                                                                                                <img src={det.Product.productImagesUrl} width='80' alt='order' />
                                                                                                {det.Product.productName}
                                                                                            </td>
                                                                                            <td style={style}>{det.itemNumber}</td>
                                                                                            <td style={style}>{Number(det.Product.productPrice).toLocaleString()}</td>
                                                                                            <td style={style}>{det.quantity}</td>
                                                                                            {/* {det.deletedAt === null && (
                                                                                <td style={style}><CloseCircleOutlined onClick={() => showRemove(det.id)} style={{ fontSize: '20px', color: 'red' }} /> Remove</td>
                                                                            )

                                                                            } */}

                                                                                        </tr>
                                                                                    }

                                                                                </>
                                                                            )
                                                                        })

                                                                        }
                                                                    </table>
                                                                </div>

                                                            </Panel>

                                                        </Collapse>
                                                    </Col>
                                                </Row>
                                                {/* <RemoveItemModal remove={remove} cancel={handleCloseRemove} itemId={itemId} /> */}
                                            </div>
                                        )
                                    })

                                    }
                                </>
                                )}
                        </TabPane>
                        <TabPane tab="Complete Orders" key="4" >
                            {loading ? (<Skeleton active />
                            ) : (
                                <>
                                    {filter.orders && filter.orders.map((data, i) => {
                                        return (
                                            <div >

                                                <Row className='order-summary'>
                                                    <Col lg={12} sm={12} xs={12}>
                                                        <Row>
                                                            <Col lg={6} sm={9} xs={9}>
                                                                <p>Order ID:  </p>
                                                                <p>Date:</p>
                                                                <p>Total</p>
                                                            </Col>
                                                            <Col lg={8} sm={15} xs={15}>
                                                                <p className='order-sub'>{data.orderNumber}</p>
                                                                <p className='order-sub'>{new Date(data.createdAt).toLocaleString('en-CA')}</p>
                                                                <p className='order-sub'>NGN {(data.orderSum.toLocaleString())}</p>
                                                            </Col>
                                                        </Row>


                                                    </Col>
                                                    <Col lg={6} sm={6} xs={6}>
                                                        <Row>
                                                            <Col lg={4} sm={12} xs={12}>
                                                                {/* <p>Buyer</p> */}
                                                                <p onClick={() => history.push(`order-details/${data.id}`)} style={{ cursor: "pointer", fontWeight: 'bolder', cursor: "pointer", color: 'blue' }}>Details</p>
                                                            </Col>
                                                            {/* <Col lg={4} sm={12} xs={12}>
                                                        <p className='order-sub'>Abimbola</p>
                                                    </Col> */}
                                                        </Row>
                                                    </Col>

                                                    <Col lg={6} sm={6} xs={6}>
                                                        <p>Order Complete</p>

                                                    </Col>

                                                    <Col lg={12}>
                                                        <Collapse accordion defaultActiveKey={['0']} >
                                                            <Panel header="Order Summary" key={i} >
                                                                <div style={{ overflowX: 'auto' }}>
                                                                    <table style={{ fontSize: '12px' }}>
                                                                        <tr >
                                                                            <th style={style}>Product Name</th>
                                                                            <th style={style}>ID</th>
                                                                            <th style={style}>Price</th>
                                                                            <th style={style}>Quantity</th>
                                                                            {/* <th style={style}>Remove Item</th> */}
                                                                        </tr>
                                                                        {data.orderDetails.map((det, i) => {
                                                                            return (
                                                                                <>
                                                                                    {det.Product && det.Product.productPrice &&
                                                                                        <tr key={i}>
                                                                                            <td style={{ width: '30%', padding: '5px 0px 5px 20px' }}>
                                                                                                <img src={det.Product.productImagesUrl} width='80' alt='order' />
                                                                                                {det.Product.productName}
                                                                                            </td>
                                                                                            <td style={style}>{det.itemNumber}</td>
                                                                                            <td style={style}>{Number(det.Product.productPrice).toLocaleString()}</td>
                                                                                            <td style={style}>{det.quantity}</td>
                                                                                            {/* {det.deletedAt === null && (
                                                                                <td style={style}><CloseCircleOutlined onClick={() => showRemove(det.id)} style={{ fontSize: '20px', color: 'red' }} /> Remove</td>
                                                                            )

                                                                            } */}

                                                                                        </tr>
                                                                                    }

                                                                                </>
                                                                            )
                                                                        })

                                                                        }
                                                                    </table>
                                                                </div>

                                                            </Panel>
                                                        </Collapse>
                                                    </Col>
                                                </Row>
                                                {/* <RemoveItemModal remove={remove} cancel={handleCloseRemove} itemId={itemId} /> */}
                                            </div>
                                        )
                                    })

                                    }
                                </>
                            )
                            }

                        </TabPane>
                    </Tabs>

                </div>

            </Navigation>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        store: state.getOneStore.data,
        orders: state.getStoreOrders.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_one_store: (id) => dispatch(store.get_one_store(id)),
        get_store_orders: (storeId) => dispatch(orders.get_store_orders(storeId)),
        get_store_orders_pagination: (storeId, page) => dispatch(orders.get_store_orders_pagination(storeId, page)),
        filter_order: (storeId, statusId) => dispatch(orders.filter_order(storeId, statusId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders)