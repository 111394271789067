import React, { useState } from 'react';
import '../../css/add-product.css'
import { Button, Form } from 'antd';
import { IKContext, IKUpload } from 'imagekitio-react';
import product from '../../services/productService';
import { connect } from 'react-redux';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};


const publicKey = 'public_sYCPM1kMSIovPWZMi7Mj83OMCBA=';
const authenticationEndpoint = 'https://api.test.getripay.com/api/v1/ecommerce/auth_params';
const urlEndpoint = 'https://ik.imagekit.io/rnh8t89owod';

export function StepThree(props) {
    const [form] = Form.useForm();
    const [url, setUrl] = useState('');
    const getProductId = JSON.parse(localStorage.getItem('current'))

    const onFinish = () => {
        const data = {
            productImagesUrl: url,
            productId: getProductId.id
        }
        console.log(data)
        props.update_product(data)
    };

    const onError = err => {
        console.log("Error", err);
    };

    const onSuccess = res => {
        console.log("Success", res);
        setUrl(res.url)
    };
    console.log(props)
    console.log(url)
    return (


        <Form
            style={{ marginTop: '50px' }}
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError >
            <IKContext
                urlEndpoint={urlEndpoint}
                publicKey={publicKey}
                authenticationEndpoint={authenticationEndpoint}
            >
                <p>Upload an image</p>
                <IKUpload
                    fileName="product.png"
                    onError={onError}
                    onSuccess={onSuccess}
                />
            </IKContext>

            <Form.Item label=' '>
                <Button disabled={url === ''} type="primary" htmlType="submit" onClick={onFinish}>
                    Submit
                    </Button>
            </Form.Item>

        </Form>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        update_product: (data) => dispatch(product.update_product(data)),
    };
};

export default connect(null, mapDispatchToProps)(StepThree)