import React, { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderBar } from '../../Apperance';
import store from '../../services/storeService';
import { Button } from 'antd';
import { history } from '../../store/history';
import { EditStoreModal } from './EditStoreModal';

const style = {
    textAlign: "center",
    marginTop: '50px'
}
const style2 = {
    textAlign: "center",
    padding: '5px',
}

const style3 = {
    textAlign: "center",
    padding: '5px',
    cursor: 'pointer'
}

export const Store = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);
    const stores = useSelector(state => state.getStore.data);
    const [show, setShow] = useState(false);
    const [storeDetails, setStoreDetails] = useState({});

    useEffect(() => {
        if (user) {
            dispatch(store.get_store(user.id))
        }
    }, [user]);

    function openModal(details) {
        setShow(true)
        setStoreDetails(details)
    }

    return (
        <Navigation {...props}>
            <HeaderBar topic="Stores" />
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div style={{ textAlign: 'right', marginBottom: "20px" }}>
                    <Button onClick={() => history.push('/create-store')}>Create new store</Button>
                </div>
                <div>
                    {stores.length === 0 ? (
                        <div style={style}>
                            <p>You do not have a store yet. Click on the button below to create a new store</p>
                            <Button type='primary' onClick={() => history.push('/create-store')}>CREATE NEW STORE</Button>
                        </div>
                    ) : (
                        <div style={{ overflowX: 'auto' }}>
                            <table style={style}>
                                <tr >
                                    <th style={style2}>Store Logo</th>
                                    <th style={style2}>Store Name</th>
                                    <th style={style2}>Store Domain</th>
                                    <th style={style2}>Store Industry</th>
                                    <th style={style2}>Created On</th>
                                    <th style={style2}>Edit Store</th>
                                </tr>
                                {stores.map((data, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td style={style2}>
                                                    <img src={data.storeImageUrl} alt='store' height='60' width='70' />
                                                </td>
                                                <td style={style2}>{data.storeName}</td>
                                                <td style={style2}>{data.preferredUrl}</td>
                                                <td style={style2}>{data.Industry && data.Industry.industryName}</td>
                                                <td style={style2}>{new Date(data.createdAt).toUTCString()}</td>
                                                <td style={style3} onClick={() => openModal(data)}>✍</td>
                                            </tr>
                                            <EditStoreModal show={show} cancel={() => setShow(false)} details={storeDetails} />
                                        </>

                                    )
                                })

                                }
                            </table>
                        </div>
                    )}
                </div>


            </div>

        </Navigation>
    )
}
