import React, { useState, useEffect } from "react";
import { Drawer, Button, Row, Col, Select } from "antd";
import store from '../services/storeService';
import { MenuOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { LoginModal } from "./LoginModal";
import { shopActions } from "../actions/creators";
import { history } from "../store/history";


const NavBar = ({ menu, user }) => {
    const dispatch = useDispatch();
    const stores = useSelector(state => state.getStore.data);
    const current = useSelector(state => state.current.data)
    const [visible, setVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (user) {
            dispatch(store.get_store(user.id))
        }
    }, [user]);

    useEffect(() => {
        if (current === null && stores[0] !== undefined) {
            dispatch(shopActions.currentStore(stores[0].id ? stores[0].id : ''))
        }
    }, [stores[0]], current);

    function onChange(value) {
        dispatch(shopActions.currentStore(value))
        setTimeout(() => {
            window.location.reload()
        }, 200)
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    function logout() {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload()
    }
    return (
        <nav className="navbar">
            <Button
                className="menu"
                type="primary"
                icon={<MenuOutlined />}
                onClick={() => setVisible(true)}
            />
            <Drawer
                placement="left"
                onClick={() => setVisible(false)}
                onClose={() => setVisible(false)}
                visible={visible}
            >
                {menu}

            </Drawer>
            <Row>
                <Col lg={11} sm={12} xs={12}>
                    <a href="/"><img src='https://getripay.lending.ng/images/getripaylogo.png' className="logo" alt="logo" /></a>
                </Col>
                <Col lg={4} sm={12} xs={12} >
                    {current !== 0 &&
                        <Select onChange={onChange} placeholder="Switch Store" style={{ width: '100%' }} defaultValue={current} >
                            {stores && stores.map((res, i) => {
                                return (
                                    <Select.Option value={res.id}><img width='25' height='25' alt='logo' src={res.storeImageUrl} style={{marginTop:'-7px'}} />{' '}{res.storeName}</Select.Option>
                                )
                            })}
                        </Select>
                    }

                </Col>
                <Col lg={3} sm={8} xs={8} className='ant-menu-item-only-child'>Welcome, {user.first_name} {user.last_name} </Col>
                <Col lg={3} sm={8} xs={8} className='ant-menu-item-only-child' style={{ cursor: 'pointer' }} onClick={() => history.push('/settings')}>Settings</Col>
                {user.length === 0 ?
                    <Col lg={3} sm={8} xs={8} className='ant-menu-item-only-child'>
                        <Button type='primary' onClick={showModal}>LOGIN</Button>
                    </Col>
                    :
                    <Col lg={3} sm={8} xs={8} className='ant-menu-item-only-child'>
                        <a href='/' onClick={logout} type='danger'>LOGOUT</a>
                    </Col>
                }

            </Row>
            <LoginModal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} user={user} />
        </nav>
    );
};
export default NavBar;