import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { history } from "../store/history";
import Joyride from 'react-joyride';

const TopicMenu = (props) => {
    const [show, setShow] = useState(true);

    const steps =
        [
            {
                target: '.create',
                content: 'Create a new store',
            },
            {
                target: '.overview',
                content: 'Check the analysis of your store',
            },
            {
                target: '.my-shop',
                content: 'Check your website',
            },
            {
                target: '.inventory',
                content: 'Create a new product and see details of your products',
            },
            {
                target: '.orders',
                content: 'Check the orders on your store',
            },
            {
                target: '.invoice',
                content: 'Create a new invoice and see details of your invoices',
            },
            {
                target: '.report',
                content: 'Get a detailed report of the activities in your store',
            }
        ]


    function setCookie(cname, cvalue) {
        document.cookie = cname + "=" + cvalue + ";";
    };

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    function checkCookie() {
        var guide = getCookie("guide");
        if (guide != "") {
            setShow(false)
        }
    };

    useEffect(() => {
        checkCookie()
    }, [])

    return (
        <>
            {show && (
                <Joyride
                    continuous
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={steps}
                    
                    styles={{
                        options: {
                            primaryColor: '#0037ba',
                            zIndex: 10000,
                        },
                    }}
                    callback={(data) => {
                        if (data.status === 'finished') {
                            setCookie("guide", "set");
                        }
                    }}
                />
            )}

            <Menu mode="inline" >
                {/* Store page */}
                {props.history.location.pathname === "/" || props.history.location.pathname === "/create-store" ?
                    (
                        <Menu.Item className='create' onClick={() => history.push('/')}
                            icon={<img src='/images/store-icon-colored.svg' alt='overview' width='30' />}>
                            Store
                        </Menu.Item>
                    )
                    :
                    (
                        <Menu.Item className='create' onClick={() => history.push('/')}
                            icon={<img src='/images/store-icon-gray.svg' alt='overview' width='30' />}>
                            Store
                        </Menu.Item>
                    )

                }
                {/* Store page */}

                {/* Overview page */}
                {props.history.location.pathname === "/overview" ?
                    (
                        <Menu.Item className='overview' onClick={() => history.push('/overview')}
                            icon={<img src='/images/overview-icon.png' alt='overview' width='30' />}>
                            Overview
                        </Menu.Item>
                    )
                    :
                    (
                        <Menu.Item className='overview' onClick={() => history.push('/overview')}
                            icon={<img src='/images/g_dashboard_icon_gray.png' alt='overview' width='30' />}>
                            Overview
                        </Menu.Item>
                    )

                }
                {/* Overview page */}


                {/* My Shop page */}
                {props.history.location.pathname === "/my-shop" ?
                    (
                        <Menu.Item className='my-shop' onClick={() => history.push('/my-shop')}
                            icon={<img src='/images/shop-icon-coloured.svg' alt='overview' width='30' />}>
                            My Shop
                        </Menu.Item>
                    )
                    :
                    (
                        <Menu.Item className='my-shop' onClick={() => history.push('/my-shop')}
                            icon={<img src='/images/shop-icon-gray.svg' alt='overview' width='30' />}>
                            My Shop
                        </Menu.Item>
                    )

                }
                {/* My Shop page */}

                {/* Inventory page */}
                {props.history.location.pathname === "/inventory" || props.history.location.pathname === "/add-product" ?
                    (
                        <Menu.Item className='inventory' onClick={() => history.push('/inventory')}
                            icon={<img src='/images/inventory-icon-colored.svg' alt='overview' width='30' />}>
                            Inventory
                        </Menu.Item>
                    )
                    :
                    (
                        <Menu.Item className='inventory' onClick={() => history.push('/inventory')}
                            icon={<img src='/images/inventory-icon-gray.svg' alt='overview' width='30' />}>
                            Inventory
                        </Menu.Item>
                    )

                }
                {/* Inventory page */}

                {/* Orders page */}
                {props.history.location.pathname === "/orders" ?
                    (
                        <Menu.Item className='orders' onClick={() => history.push('/orders')}
                            icon={<img src='/images/orders-icon-colored.svg' alt='overview' width='30' />}>
                            Orders
                        </Menu.Item>
                    )
                    :
                    (
                        <Menu.Item className='orders' onClick={() => history.push('/orders')}
                            icon={<img src='/images/orders-icon-gray.svg' alt='overview' width='30' />}>
                            Orders
                        </Menu.Item>
                    )

                }
                {/* Orders page */}


                {/* Invoice page */}
                {props.history.location.pathname === "/invoice" || props.history.location.pathname === "/generate-invoice" ?
                    (
                        <Menu.Item className='invoice' onClick={() => history.push('/invoice')}
                            icon={<img src='/images/invoice-colored.svg' alt='overview' width='30' />}>
                            Invoice
                        </Menu.Item>
                    )
                    :
                    (
                        <Menu.Item className='invoice' onClick={() => history.push('/invoice')}
                            icon={<img src='/images/invoice-gray.svg' alt='overview' width='30' />}>
                            Invoice
                        </Menu.Item>
                    )

                }
                {/*Invoice page */}

                 {/* Report page */}
                 {props.history.location.pathname === "/report"?
                    (
                        <Menu.Item className='report' onClick={() => history.push('/report')}
                            icon={<img src='/images/inventory-icon-colored.svg' alt='overview' width='30' />}>
                            Report
                        </Menu.Item>
                    )
                    :
                    (
                        <Menu.Item className='report' onClick={() => history.push('/report')}
                            icon={<img src='/images/inventory-icon-gray.svg' alt='overview' width='30' />}>
                            Report
                        </Menu.Item>
                    )

                }
                {/*Invoice page */}

            </Menu>

        </>


    );
}
export default TopicMenu;