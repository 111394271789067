import { shopConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    isLoading3:false,
    data: [],
    oneOrder: []
};

function getStoreOrders(state = initialState, action) {
    switch (action.type) {
        case shopConstants.GET_STORE_ORDERS_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.GET_STORE_ORDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        default:
            return state
    }
}

function getOneOrder(state = initialState, action) {
    switch (action.type) {
        case shopConstants.GET_ONE_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.GET_ONE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                oneOrder: action.payload,
            };
        default:
            return state
    }
}

function changeOrderStatus(state = initialState, action) {
    switch (action.type) {
        case shopConstants.CHANGE_ORDER_STATUS_REQUEST:
            return {
                ...state,
                isLoading3: true,

            };
        case shopConstants.CHANGE_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                isLoading3: false,
            };
        default:
            return state
    }
}

export {
    getStoreOrders,
    getOneOrder,
    changeOrderStatus
} ;