import React, { useEffect } from "react";
import Navigation from "../components/Navigation";
import { Row, Col, Tabs } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";
import { connect, useSelector } from "react-redux";
import store from "../services/storeService";
import product from "../services/productService";
import auth from "../services/authService";
import "../css/overview.css";
import qr from "../services/qrService";
import { history } from "../store/history";
import orders from "../services/orderService";
import { HeaderBar } from "../Apperance";
import wallet from "../services/walletService";
import report from "../services/reportService";

const { TabPane } = Tabs;

const Overview = (props) => {
  const user = useSelector((state) => state.user.data);
  const current = useSelector((state) => state.current.data);
  const storeId = props.store ? props.store.id : "";
  const account_no =
    user && user.bank_account ? user.bank_account[0].account_number : "";

  const data =
    props.products.products &&
    props.products.products.map((data, i) => {
      return {
        key: i,
        name: data.productName,
        pv: data.TotalInStock,
        uv: data.productPrice,
        amt: data.TotalInStock,
      };
    });

  const topProducts = props.products.products
    ? props.products.products.slice(0, 2)
    : [];

  useEffect(() => {
    props.get_one_store(current);
    props.get_account_balance(account_no);
    props.get_store_qr(user.id);
  }, []);

  useEffect(() => {
    if (storeId) {
      props.get_products(storeId);
      props.get_store_orders(storeId);
      props.wallet_transactions(storeId);
      props.top_items(storeId)
    }
  }, [storeId]);

  const totalOrderValue = props.orders.orders
    ? props.orders.orders.reduce(
        (sum, price) => sum + Number(price.orderSum),
        0
      )
    : 0;

    console.log(props.topItems)

  const base = "http://api.test.getripay.com/api/v1/qr/show_qr_image/";
  const myQr =
    props.qr[0] && props.qr[0].submerchant[0]
      ? base + props.qr[0].submerchant[0].emvco_code
      : "";

  return (
    <Navigation {...props}>
      <HeaderBar topic="Overview" />
      {/* <h4 className='page-title'>{props.store && props.store.storeName}</h4> */}
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Account Summary" key="1">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                className="gutter-row"
                span={16}
                xs={24}
                sm={24}
                md={12}
                lg={8}
              >
                <div className="dashboard-box">
                  <div className="bal-text">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <p className="overview-number">
                          SAVINGS ACCOUNT NUMBER
                        </p>
                        <p className="overview-account">
                          {props.balance.account_number}
                        </p>
                      </Col>
                      {/* <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                        <p className='bvn-text'>BVN</p>
                                        <p className='overview-account'>{user.bvn}</p>
                                    </Col> */}
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <p className="bvn-text">BALANCE</p>
                        <p className="avail-balance">
                          NGN{" "}
                          {Number(
                            props.balance.available_balance
                          ).toLocaleString()}
                        </p>
                      </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <p className="bvn-text">LEDGER BALANCE</p>
                        <p className="ledger-balance">
                          NGN{" "}
                          {Number(props.balance.book_balance).toLocaleString()}
                        </p>
                      </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        style={{ textAlign: "right", marginTop: "-130px" }}
                      >
                        <img src={myQr} className="qr-image" />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col
                className="gutter-row"
                span={8}
                xs={24}
                sm={24}
                md={12}
                lg={8}
              >
                <div className="dashboard-box">
                  <p className="overview-number">WALLET NUMBER</p>
                  <p
                    className="overview-account"
                    style={{ marginLeft: "10px" }}
                  >
                    {props.store.walletNumber}
                  </p>

                  <p className="bvn-text">WALLET BALANCE</p>
                  <p className="avail-balance" style={{ marginLeft: "10px" }}>
                    NGN {Number(props.store.walletBalance).toLocaleString()}
                  </p>
                </div>
              </Col>

              <Col
                className="gutter-row"
                span={8}
                xs={24}
                sm={24}
                md={12}
                lg={8}
              >
                <div className="dashboard-box">
                  <h3>Total Value of Orders </h3>
                  <h4 style={{ color: "red" }}>
                    NGN {totalOrderValue.toLocaleString()}
                  </h4>

                  <h3>Total Volume of Orders </h3>
                  <h4 style={{ color: "green" }}>
                    {props.orders.totalItems} Orders
                  </h4>

                  {/* <ResponsiveContainer width="100%" height={130}>
                                <LineChart width={300} height={100} data={data}>
                                    <Line type="monotone" dataKey="pv" stroke="red" strokeWidth={5} />
                                </LineChart>
                            </ResponsiveContainer> */}
                </div>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                className="gutter-row"
                span={8}
                xs={24}
                sm={24}
                md={12}
                lg={8}
              >
                <div className="dashboard-box">
                  <h3>Total Products In Store</h3>
                  <div style={{ display: "flex" }}>
                    <h4>{props.products.totalItems} products</h4>
                    <p
                      onClick={() => history.push("/")}
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        fontWeight: "bolder",
                        margin: "5px 0px 0px 10px",
                      }}
                    >
                      View Products
                    </p>
                  </div>

                  <ResponsiveContainer width="100%" height={130}>
                    <LineChart width={300} height={100} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="green"
                        strokeWidth={5}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Col>

              <Col
                className="gutter-row"
                span={8}
                xs={24}
                sm={24}
                md={12}
                lg={8}
              >
                <div className="dashboard-box">
                  <h3 style={{ textAlign: "left" }}>Top Selling Items</h3>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {topProducts.map((data, i) => {
                      return (
                        <Col span={24} lg={12} sm={12} xs={12}>
                          <img
                            src={data.productImagesUrl}
                            height="120"
                            width="120"
                            alt="items"
                          />
                          <p style={{ fontWeight: "bolder" }}>
                            {data.productName}
                          </p>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>

              <Col
                className="gutter-row"
                span={8}
                xs={24}
                sm={24}
                md={12}
                lg={8}
              >
                <div className="dashboard-box">
                  <ResponsiveContainer width="100%" height={190}>
                    <BarChart
                      width={300}
                      height={300}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="pv" fill="#8884d8" />
                      <Bar dataKey="uv" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Col>

              <Col
                className="gutter-row"
                span={8}
                xs={24}
                sm={24}
                md={12}
                lg={8}
              >
                <div className="dashboard-box">
                  <h3>To be shipped </h3>
                  <h4>30</h4>
                </div>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* <Col className="gutter-row" span={8} xs={24} sm={24} md={12} lg={8}>
                        <div className='dashboard-box'>
                            <h3>Total Amount Sold </h3>
                            <h4>NGN 3,000,000</h4>
                        </div>
                    </Col> */}
            </Row>
          </TabPane>

          <TabPane tab="Wallet Transactions" key="2">
            <h3
              style={{
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              Wallet Transactions
            </h3>

            {props.transactions.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <img src="/images/empty.svg" alt="empty-wallet" width="200" />
                <h4>You do not have any wallet tranasaction on this store</h4>
              </div>
            ) : (
              <>
                {props.transactions.map((res, i) => {
                  return (
                    <div className="wallet-box" key={i}>
                      <Row>
                        <Col lg={8} sm={24} xs={24}>
                          Wallet No: <span>{res.walletNumber}</span>
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                          Amount: <span>{res.amount}</span>
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                          Txn type: <span>{res.transactionType}</span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={8} sm={24} xs={24}>
                          Order Id: <span>{res.orderTransactionId}</span>{" "}
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                          Date: <span>{res.createdAt}</span>{" "}
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                          Txn Ref: <span>{res.transactionRef}</span>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </>
            )}
          </TabPane>
        </Tabs>
      </div>
    </Navigation>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.getOneStore.data,
    products: state.getProducts.data,
    orders: state.getStoreOrders.data,
    balance: state.accountBalance.data,
    qr: state.getStoreQr.data,
    transactions: state.walletTransactions.data,
    topItems:state.topItems.topItems
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_one_store: (store_id) => dispatch(store.get_one_store(store_id)),
    get_products: (storeId) => dispatch(product.get_products(storeId)),
    get_store_orders: (storeId) => dispatch(orders.get_store_orders(storeId)),
    get_account_balance: (account_no) =>
      dispatch(auth.get_account_balance(account_no)),
    get_store_qr: (user_id) => dispatch(qr.get_store_qr(user_id)),
    wallet_transactions: (store_id) =>
      dispatch(wallet.wallet_transactions(store_id)),
      top_items:(storeId)=> dispatch(report.top_items(storeId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
