import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from "react-select";
import settings from '../../services/settingsService';

//const { Option } = Select;

export const PaymentConfig = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();


    const options = props.all.map((res, i) => ({
        label: res.paymentOption,
        value: res.id,
        key: i,
    }));

    function handleChange(selectedOption) {
        setSelectedOption(selectedOption);
    };

    function sub() {
        const paymentOptionsConfigs = selectedOption.map((res, i) => {
            return {
                paymentOption: res.label,
                id: res.value
            }
        })
        const data = {
            storeId: props.storeId,
            paymentOptionsConfigs: paymentOptionsConfigs
        }

        if (data && props.storeId) {
            dispatch(settings.update_payment_config(data))
        }
    }
    return (
        <div>
            <h3>Payment Settings</h3>
            <div className='account-details-box'>
                <p>Your current payment configurations are <br /> <span style={{ color: '#0037ba', fontWeight: 'bolder', fontSize: '16px' }}>{props.list && (props.list).toString()} </span> </p>
                <p>Click on the dropdown below to update your payment configurations</p>

                <Select
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "black" },
                            border: "none",
                            borderRadius: "none",
                            borderBottom: "1px solid black",
                            boxShadow: "none",
                        }),
                    }}
                    isMulti
                    placeholder="Select Account"
                    onChange={handleChange}
                    value={selectedOption}
                    options={options}
                />

                <Button type="primary" onClick={sub}>
                    Submit
                    </Button>
            </div>


        </div>
    )
}
