import { Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { useDispatch } from 'react-redux'
import orders from '../../services/orderService'

export const AcceptModal = (props) => {
    const dispatch = useDispatch();

    async function changeStatus(val) {
        const data = {
            orderStatusId: val,
            orderId: props.id
        }
        dispatch(orders.change_order_status(data))
    }

    return (
        <>
            <Modal footer={null} title="Accept Order" visible={props.accept} onCancel={props.cancel} style={{textAlign:'center'}}>
                <img src='/images/accept1.svg' alt='accept-order' width='150' />
                <p>Are you sure you want to accept this order? </p>
                <Button type="primary" onClick={() => changeStatus(2)}>
                    Yes
                </Button>
            </Modal>
        </>
    )
}
