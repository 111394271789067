import React from "react";
import { Table } from "ant-table-extensions";
import { topColumns } from "../../components/data";
import { CSVLink } from "react-csv";
import { Button } from "antd";

export const TopSellingItems = (props) => {
  const dataSource =
    props.topItems &&
    props.topItems.map((pro, i) => {
      return {
        key: i,
        name: pro.productId,
        count: pro.count,
      };
    });

  return (
    <div>
      <div style={{ textAlign: "right", marginBottom:'10px' }}>
        <CSVLink data={props.topItems} filename={"top-selling-report.csv"}>
          <Button type="primary">Export to CSV</Button>
        </CSVLink>
      </div>

      <Table
        scroll={{ x: 1400, y: 600 }}
        dataSource={dataSource}
        columns={topColumns}
        searchable
        // onChange={onChange}
        sticky
      />
    </div>
  );
};
