import { history } from "../store/history";

export const columns = [
  {
    dataIndex: "image",
    title: "📸",
    key: "image",
    render: (cell) => <img src={cell} width="100" height="50" alt="table" />,
  },
  {
    dataIndex: "name",
    key: "name",
    title: "Name",
    render: (text) => <p className="text-primary">{text}</p>,
    responsive: ["sm"],
  },
  {
    dataIndex: "description",
    key: "description",
    title: "Description",
    responsive: ["sm"],
  },
  {
    dataIndex: "category",
    key: "category",
    title: "Category",
  },
  {
    dataIndex: "subcategory",
    key: "subcategory",
    title: "SubCategory",
  },
  {
    dataIndex: "price",
    key: "Price",
    title: "Price(NGN)",
  },
  {
    dataIndex: "quantity",
    key: "quantity",
    title: "Quantity",
  },
  {
    dataIndex: "type",
    key: "type",
    title: "Product Type",
  },
  {
    dataIndex: "tags",
    key: "tags",
    title: "Product Tags",
  },

  {
    dataIndex: "date",
    key: "date",
    title: "Date added",
  },
  // {
  //     dataIndex: 'status',
  //     key: 'status',
  //     title: 'Status',
  //     render: stat => {
  //         let color = stat === 'In stock' ? 'green' : stat === 'Sold out' ? 'red' : stat === 'Low stock' ? 'yellow' : 'green';
  //         return (
  //             <Tag color={color} key={stat}>
  //                 {stat}
  //             </Tag>
  //         );

  //     },
  // },
  {
    dataIndex: "action",
    key: "action",
    title: "Edit",
    render: (value, record) => (
      <p
        style={{ cursor: "pointer" }}
        onClick={() => history.push(`/edit-product/${record.key}`)}
      >
        {value}
      </p>
    ),
  },
];

export const topColumns = [
  {
    dataIndex: "name",
    key: "name",
    title: "Product Name",
    responsive: ["sm"],
  },
  {
    dataIndex: "count",
    key: "count",
    title: "Number Sold",
    responsive: ["sm"],
  },
];

export const viewsColumns = [
  {
    dataIndex: "name",
    key: "name",
    title: "Product Name",
    responsive: ["sm"],
  },
  {
    dataIndex: "count",
    key: "count",
    title: "Number of times viewed",
    responsive: ["sm"],
  },
];

export const checkoutViewsColumns = [
  {
    dataIndex: "name",
    key: "name",
    title: "Product Name",
    responsive: ["sm"],
  },
  {
    dataIndex: "count",
    key: "count",
    title: "Number of times product got to checkout",
    responsive: ["sm"],
  },
];

export const salesColumns = [
  {
    dataIndex: "name",
    key: "name",
    title: "Product Name",
    responsive: ["sm"],
  },
  {
    dataIndex: "price",
    key: "price",
    title: "Unit Price",
    responsive: ["sm"],
  },
  {
    dataIndex: "quantity",
    key: "quantity",
    title: "Quantity",
    responsive: ["sm"],
  },
  {
    dataIndex: "total",
    key: "total",
    title: "Total",
    responsive: ["sm"],
  },
];

export const profitLossColumns = [
  {
    dataIndex: "name",
    key: "name",
    title: "Product Name",
    responsive: ["sm"],
  },
  {
    dataIndex: "profitLoss",
    key: "profitLoss",
    title: "Profit/Loss",
    responsive: ["sm"],
  },
];


