import React, { useEffect } from 'react'
import { Tabs } from 'antd';
import Navigation from '../components/Navigation';
import { useSelector, useDispatch } from 'react-redux';
import { AccountDetails } from '../components/Settings/AccountDetails';
import settings from '../services/settingsService';
import store from '../services/storeService';
import { PaymentConfig } from '../components/Settings/PaymentConfig';
import { HeaderBar } from '../Apperance';

const { TabPane } = Tabs;

export const Settings = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data)
    const userStore = useSelector(state => state.getOneStore.data)
    const storeConfig = useSelector(state => state.getStorePaymentConfig.data)
    const allConfig = useSelector(state => state.getAllPaymentConfig.all)
    const current = useSelector(state => state.current.data)
    const storeId = userStore && userStore.id;

    useEffect(() => {
        dispatch(store.get_one_store(current))
    }, []);

    useEffect(() => {
        if (storeId) {
            dispatch(settings.get_all_payment_config())
            dispatch(settings.get_store_payment_config(storeId))
        }
    }, [storeId]);

    const list = storeConfig.paymentOptionsConfigs && storeConfig.paymentOptionsConfigs.map((res) => res.paymentOption);

    return (
        <Navigation {...props}>
              <HeaderBar topic='Settings'/>
            <h4 className='page-title'>My Settings</h4>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Account Details" key="1">
                        <AccountDetails {...user} />
                    </TabPane>
                    <TabPane tab="Payment settings" key="2">
                        <PaymentConfig list={list} all={allConfig} storeId={storeId && storeId} />
                    </TabPane>
                    <TabPane tab="Ati be be lo settings" key="3">
                        Ati be be lo settings
                    </TabPane>
                </Tabs>
            </div>

        </Navigation>
    )
}
