import { Button, Table } from 'antd';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { HeaderBar } from '../../Apperance';
import Navigation from '../../components/Navigation';
import invoice from '../../services/invoiceService';
import { history } from '../../store/history';

const Invoice = (props) => {
    const invoice = useSelector(state => state.getInvoice.data);

    useEffect(() => {
        props.get_invoice(props.user.id)
    }, []);

    function onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    const column = [
        {
            dataIndex: 'ref',
            key: 'ref',
            title: 'Invoice No',
        },
        {
            dataIndex: 'date',
            key: 'date',
            title: 'Date',
        },
        {
            dataIndex: 'subTotal',
            key: 'subTotal',
            title: 'Sub Total',
        },
        {
            dataIndex: 'sender',
            key: 'sender',
            title: 'Sender',
        },
        {
            dataIndex: 'sAddress',
            key: 'sAddress',
            title: "Sender's Address",
        },
        {
            dataIndex: 'receiver',
            key: 'receiver',
            title: 'Receiver',
        },
        {
            dataIndex: 'rAddress',
            key: 'rAddress',
            title: "Receiver's Address",
        },
        {
            dataIndex: 'action',
            key: 'action',
            title: 'Download',
            render: (value, record) => <p style={{ cursor: 'pointer' }} onClick={()=>history.push(`/invoice-details/${record.ref}`)}>{value}</p>
        },
    ]
    const dataSource = invoice.map((data, i) => {
        return {
            key: data.id,
            ref: data.invoiceNumber,
            html: data.invoiceHtml,
            date: new Date(data.createdAt).toLocaleString(),
            subTotal: Number(data.subTotal).toLocaleString(),
            sender: data.sender && data.sender.company,
            sAddress: data.sender && data.sender.address,
            receiver: data.sender && data.client.company,
            rAddress: data.sender && data.client.address,
            action: '✍',
            number: data.invoiceNumber
        }
    });



    return (
        <Navigation {...props}>
            <HeaderBar topic='Invoice' />
            <h4 className='page-title'>My Invoices</h4>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div style={{ textAlign: 'right', marginBottom: "20px" }}>
                    <Button type='primary' onClick={() => history.push('/generate-invoice')}>Create new invoice</Button>
                </div>

                <Table scroll={{ x: 1400, y: 600 }} dataSource={dataSource} columns={column} searchable onChange={onChange} sticky />
            </div>
        </Navigation>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_invoice: (id) => dispatch(invoice.get_invoice(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)