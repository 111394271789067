import React from "react";
import { Table } from 'ant-table-extensions';
import { profitLossColumns  } from "../../components/data";
import { CSVLink} from "react-csv";
import { Button } from "antd";

export const ProfitLoss = (props) => {

    
  const dataSource =
    props.profitLoss &&
    props.profitLoss.map((pro, i) => {
      return {
        key: i,
        name: pro.productId,
        profitLoss: pro.profitLoss,
      };
    });

  return (
    <div>
     <div style={{ textAlign: "right", marginBottom:'10px' }}>
        <CSVLink data={props.profitLoss} filename={"profit-loss-report.csv"}>
          <Button type="primary">Export to CSV</Button>
        </CSVLink>
      </div>
      <Table
        scroll={{ x: 1400, y: 600 }}
        dataSource={dataSource}
        columns={profitLossColumns }
        searchable
        // onChange={onChange}
        sticky
      />
    </div>
  );
};
