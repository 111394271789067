
import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';
import swal from 'sweetalert'
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const website = {
    // To get a user's shop website

    get_website: (storeId) => {
        return async dispatch => {
            dispatch(shopActions.getWebsiteRequest());

            await axios.get(`${baseUrl}/getrishop/api/webstore/${storeId}`)
                .then((res) => {
                    dispatch(shopActions.getWebsiteSuccess(res.data.data));
                });
        }
    },

    update_website: (storeId, website) => {
        return async dispatch => {
            dispatch(shopActions.updateWebsiteRequest());

            await axios.put(`${baseUrl}/getrishop/api/webstore/${storeId}`, website)
                .then((res) => {
                    dispatch(shopActions.updateWebsiteSuccess(res.data));
                    swal("Success!", 'Template changed successfully', "success");
                    history.push('/my-shop')
                }).catch(()=> {
                    swal("Oh oh", 'Could not change template. Please try again later', "error");
                })
        }
    },

    get_templates: () => {
        return async dispatch => {
            dispatch(shopActions.getTemplatesRequest());

            await axios.get(`${baseUrl}/getrishop/api/templates`, website)
                .then((res) => {
                    dispatch(shopActions.getTemplatesSuccess(res.data));
                });
        }
    },


}
export default website;