
import React from 'react';
import { Modal, Steps } from 'antd';

const { Step } = Steps;


export const StatusHistoryModal = (props) => {
    return (
        <>
            <Modal footer={null} title="Status History" visible={props.open} onCancel={props.close} style={{ textAlign: 'center' }}>

                <Steps direction="vertical" current={1}>
                    {props.orderHistory.map((res, i) => {
                        return (
                            <Step title={res.orderStatus} description={`On ${(res.createdAt).toLocaleString()}`} />
                        )
                    })

                    }
                </Steps>
            </Modal>
        </>
    )
}
