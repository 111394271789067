import { Modal, Form, Input, Button } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import auth from '../services/authService';

export const LoginModal = (props) => {
    const dispatch = useDispatch();
    const [answer, setAnswer] = useState('');
    const loadingLogin = useSelector(state => state.loginReducer.isLoading);
    const loadingVerify = useSelector(state => state.user.isLoading);
    const success = useSelector(state => state.loginReducer.data);
    const newQuestion = useSelector(state => state.user.error)
    const ques = newQuestion !== undefined ? newQuestion.question : success.question;


    const [data, setData] = useState({
        email: '',
        password: '',
        channel: 'web',
    })

    function handleLogin(e) {
        const { name, value } = e.target;
        setData(data => ({ ...data, [name]: value }));
    }

    function login() {
        dispatch(auth.login_user(data))
    }

    function verify() {
        dispatch(auth.verify_login(success.answer_id, answer))
    }
    return (
        <Modal title={success.length !== 0 ? "Answee your security question" : "Log in to your getripay account"} visible={props.visible || props.user.length === 0 && props.user.length === 0} onOk={props.onOk} onCancel={props.onCancel} footer={null}>
            <div style={{ textAlign: 'center' }}>
                <img src='/images/login1.svg' alt='login' width='200' height='100' />
            </div>
            {success.length === 0 ?
                <>
                    <Form >
                        <p style={{ marginBottom: '-15px', marginLeft: "7px", fontSize: '13px' }}>Email/Phone Number/Username</p>
                        <Form.Item>
                            <Input type='text' onChange={handleLogin} name='email' value={data.email} />
                        </Form.Item>

                        <p style={{ marginBottom: '-15px', marginLeft: "7px", fontSize: '13px' }}>Password</p>
                        <Form.Item>
                            <Input type='password' onChange={handleLogin} name='password' value={data.password} />
                        </Form.Item>
                    </Form>
                    <div style={{ textAlign: 'center' }}>
                        {loadingLogin ?
                            <Button type='primary' loading disabled>LOGIN</Button>
                            :
                            <Button type='primary' onClick={login}>LOGIN</Button>
                        }

                    </div>
                </>
                :
                <>
                    <Form >
                        <p>{ques}</p>
                        <p style={{ marginBottom: '-15px', marginLeft: "7px", fontSize: '13px' }}>Answer to question</p>
                        <Form.Item>
                            <Input type='password' onChange={(e) => setAnswer(e.target.value)} name='answer' value={answer} />
                        </Form.Item>
                    </Form>
                    <div style={{ textAlign: 'center' }}>
                        {/* {loadingVerify ?
                            <Button type='primary' disabled>VERIFY</Button>
                            : */}
                        <Button type='primary' onClick={verify}>VERIFY</Button>
                        {/* } */}

                    </div>
                </>

            }

        </Modal>
    )
}
