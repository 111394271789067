import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';
import { history } from "../store/history";


const baseUrl = `${config.apiUrl}`;

const product_requirements = {
    // To get product types
    get_types: () => {
        return async dispatch => {
            dispatch(shopActions.productReqRequest());

            await axios.get(`${baseUrl}/ecommerce/product_types`)
                .then((res) => {
                    dispatch(shopActions.productTypesSuccess(res.data.data));
                });
        }
    },

    // To get product properties
    get_properties: () => {
        return async dispatch => {
            dispatch(shopActions.productReqRequest());

            await axios.get(`${baseUrl}/ecommerce/properties`)
                .then((res) => {
                    dispatch(shopActions.productPropertiesSuccess(res.data.data));
                });
        }
    },

    // To get product properties
    get_categories: () => {
        return async dispatch => {
            dispatch(shopActions.productReqRequest());

            await axios.get(`${baseUrl}/ecommerce/categories`)
                .then((res) => {
                    dispatch(shopActions.productCategoriesSuccess(res.data.data));
                });
        }
    },

}
export default product_requirements;