import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';
import { history } from "../store/history";
import swal from "sweetalert";

const baseUrl = `${config.apiUrl}`;

const store = {
    // To create a new store
    create_store: (data) => {
        return async dispatch => {
            dispatch(shopActions.createStoreRequest());

            await axios.post(`${baseUrl}/ecommerce/stores`, data)
                .then((res) => {
                    dispatch(shopActions.createStoreSuccess());
                    swal("Success!", 'Store created successfully. Now choose a template for your store', "success");
                    history.push('/my-shop')
                }).catch((err)=>{
                    swal("Oh oh!", 'An error occured. Please try again later', "error");
                })
        }
    },

    // To get a user's stores
    get_store: (id) => {
        return async dispatch => {
            dispatch(shopActions.getStoreRequest());

            await axios.get(`${baseUrl}/ecommerce/stores/${id}`)
                .then((res) => {
                    dispatch(shopActions.getStoreSuccess(res.data.data));
                });
        }
    },

    //To edit a store
    edit_store: (storeId,data) => {
        return async dispatch => {
            dispatch(shopActions.editStoreRequest());

            await axios.put(`${baseUrl}/ecommerce/store/${storeId}`, data)
                .then((res) => {
                    dispatch(shopActions.editStoreSuccess(res.data.data));
                    swal("Success!", 'Store updated successfully', "success");
                    window.location.reload()
                });
        }
    },

     // To get one store
     get_one_store: (store_id) => {
        return async dispatch => {
            dispatch(shopActions.getOneStoreRequest());

            await axios.get(`${baseUrl}/ecommerce/store/${store_id}`)
                .then((res) => {
                    dispatch(shopActions.getOneStoreSuccess(res.data.data));
                });
        }
    },

    // To get a store
    get_industries: () => {
        return async dispatch => {
            dispatch(shopActions.getIndustriesRequest());

            await axios.get(`${baseUrl}/ecommerce/industries`)
                .then((res) => {
                    dispatch(shopActions.getIndustriesSuccess(res.data.data));
                });
        }
    },

}
export default store;