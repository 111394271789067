import { Col, Row } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "./services/storeService";
import website from "./services/websiteService";

export const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const current = useSelector((state) => state.current.data);
  const oneStore = useSelector((state) => state.getOneStore.data);
  const site = useSelector((state) => state.getWebsite.data);

  useEffect(() => {
    dispatch(website.get_website(current));
    dispatch(store.get_one_store(current));
  }, []);

  return (
    <div className="header-crumb">
      <Row>
        <Col xs={24} sm={24} md={8} lg={8}>
          <div className="topic">
            <h4>{props.topic}</h4>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <div className="menuList">
            <div>
              <span>
                <span> </span>
                <a
                  href={`http://${site.website_domain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: "bolder",
                    color: "red",
                    marginRight: "10px",
                  }}
                >
                  {site.website_domain}
                </a>
              </span>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8}>
          <div className="topic">
            <h5>{oneStore.storeName}</h5>
          </div>
        </Col>
      </Row>
    </div>
  );
};
