
import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';

const baseUrl = `${config.apiUrl}`;

const wallet = {
    // To get a store's wallet transactions

    wallet_transactions: (storeId) => {
        return async dispatch => {
            dispatch(shopActions.getWalletTransactionsRequest());

            await axios.get(`${baseUrl}/ecommerce/wallet/txns/${storeId}`)
                .then((res) => {
                    dispatch(shopActions.getWalletTransactionsSuccess(res.data.data));
                });
        }
    }


}
export default wallet;