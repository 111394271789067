import React, { useEffect } from 'react';
import Navigation from '../../components/Navigation';
import { Table } from 'ant-table-extensions';
import { columns } from '../../components/data';
import { connect, useSelector } from 'react-redux';
import product from '../../services/productService';
import store from '../../services/storeService';
import { history } from '../../store/history';
import { Button } from 'antd';

function Inventory(props) {
    const current = useSelector(state => state.current.data)

    const onChange = (pagination, filters, sorter) => {
        console.log(pagination)
        props.get_products_pagination(current, pagination.current
            // (pagination.current * pagination.pageSize) - pagination.pageSize, pagination.pageSize,
            // {
            //     order: sorter.hasOwnProperty('column') ? sorter : false
            // }
        )

    }


    const user = useSelector(state => state.user.data)

    const dataSource = props.products.products && props.products.products.map((pro, i) => {
        return {
            key: pro.id,
            name: pro.productName,
            image: pro.productImagesUrl && (pro.productImagesUrl).split(',')[0],
            category: pro.categoryName,
            subcategory: pro.productSubCategory,
            price: Number(pro.updatedPrice).toLocaleString(),
            quantity: pro.TotalInStock,
            date: new Date(pro.updatedAt).toDateString(),
            type: pro.productTypeName,
            tags: pro.productTags,
            description: pro.productDescription,
            action: '✍',
        }
    });

    useEffect(() => {
        if (current !== null && user) {
            props.get_products(current)
        }
    }, [current, user]);


    return (
        <>
            <Navigation {...props}>
                <h4 className='page-title'>My Inventory</h4>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <div style={{ textAlign: 'right', marginBottom: "20px" }}>
                        <Button type='primary' onClick={() => history.push('/add-product')}>Add new product</Button>
                    </div>

                    <Table scroll={{ x: 1400, y: 600 }} dataSource={dataSource} columns={columns}
                        pagination={{
                            pageSize: 10,
                            total: props.products.totalItems,
                            current: props.products.currentPage,
                            defaultCurrent:0
                        }}
                        searchable onChange={onChange} sticky />
                </div>
            </Navigation>



        </>
    )
};

const mapStateToProps = (state) => {
    return {
        store: state.getOneStore.data,
        products: state.getProducts.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_one_store: (id) => dispatch(store.get_one_store(id)),
        get_products: (storeId) => dispatch(product.get_products(storeId)),
        get_products_pagination: (storeId, page) => dispatch(product.get_products_pagination(storeId, page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory)