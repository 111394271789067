
import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';

const baseUrl = `${config.apiUrl}`;

const settings = {
    // To list a store's payment configurations
    get_store_payment_config: (storeId) => {
        return async dispatch => {
            dispatch(shopActions.getStorePaymentConfigRequest());

            await axios.get(`${baseUrl}/ecommerce/payment_options/config/${storeId}`)
                .then((res) => {
                    dispatch(shopActions.getStorePaymentConfigSuccess(res.data.data));
                });
        }
    },

    // To get a list of all payment configurations
    get_all_payment_config: () => {
        return async dispatch => {
            dispatch(shopActions.getAllPaymentConfigRequest());

            await axios.get(`${baseUrl}/ecommerce/payment_options`)
                .then((res) => {
                    dispatch(shopActions.getAllPaymentConfigSuccess(res.data.data));
                });
        }
    },

    // To update a store's payment configuration.
    update_payment_config: (data) => {
        return async dispatch => {
            dispatch(shopActions.updateStorePaymentConfigRequest());

            await axios.put(`${baseUrl}/ecommerce/payment_options/config/${data.storeId}`, data)
                .then((res) => {
                    dispatch(shopActions.updateStorePaymentConfigSuccess(res.data.data));
                    alert('Payment configurations changed successfully')
                });
        }
    },

}
export default settings;