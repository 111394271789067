import { shopConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: [],
};

function createStore(state = initialState, action) {
    switch (action.type) {
        case shopConstants.CREATE_STORE_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.CREATE_STORE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: true,
            };
        default:
            return state
    }
}

export default createStore;