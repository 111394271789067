import { shopActions } from "../actions/creators";
import config from "../config";
import axios from 'axios';
import { message } from "antd";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const auth = {
    // To get a user's details


    // User Login
    login_user: (data) => {
        return async dispatch => {
            dispatch(shopActions.loginRequest());

            await axios.post(`${baseUrl}/user/users/login`, data)
                .then(res => {
                    dispatch(shopActions.loginSuccess(res.data.data));
                    localStorage.setItem('email', (res.data.data.email));
                    localStorage.setItem('register_token', (res.data.data.register_token))
                    localStorage.setItem('user_id', (res.data.data.user_id))

                    if (res.data.response_code === 'USM006') {
                        message.success("Success. Kindly provide an answer to your security question below")
                    }
                    else if (res.data.response_code === "USM004") {
                        message.error('Your account has not been verified, Please visit getripay to verify your account')
                    }
                })
                .catch(error => {
                    dispatch(shopActions.loginFailure());

                    if (error.response.data.response_message === "Incorrect password") {
                        message.error('Incorrect Credentials')
                    }
                    else if (error.response.data.response_code === "USM012") {
                        message.error('You do not have an account, please register')
                    } else if (error.response.data.response_code === "USM027") {
                        message.error('You have not set your security question. Please visit getripay to set your security questions')
                        localStorage.setItem('user_id', (error.response.data.response_message.user_id))

                    } else if (error.response.data.response_code === "USM031") {
                        message.error(error.response.data.data)
                    }
                    else {
                        message.error('ERROR')
                    }

                })
        }
    },

    verify_login: (answer_id, answer) => {
        return async dispatch => {

            dispatch(shopActions.getUserRequest());

            const register_token = localStorage.getItem('register_token');
            const email = localStorage.getItem('email')
            const user_id = localStorage.getItem('user_id');

            await axios.post(`${baseUrl}/user/users/login-web-question-answer-verify`, { register_token, email, user_id, answer_id, answer })
                .then(res => {
                    dispatch(shopActions.getUserSuccess(res.data.data));
                    console.log(res.data.data)
                    localStorage.setItem('user_id', (res.data.data.id));
                    localStorage.setItem('token', (res.data.data.token));
                    localStorage.setItem('account', (res.data.data.bank_account[0].account_number))
                    localStorage.setItem('kyc', (res.data.data.kyc_level))

                    if (res.data.response_code === 'USM003') {
                        message.success("Login Successful")
                    }
                    setTimeout(() => {
                        window.location.reload()
                    }, 200)
                })
                .catch(error => {
                    dispatch(shopActions.getUserFailure(error.response.data.response_message));
                    console.log(error.response.data)
                    if (error.response.data.response_code === "USM026") {
                        message.error('Incorrect Answer. Please answer the next question')
                    }
                });
        }
    },

    get_user: (id) => {
        return async dispatch => {
            dispatch(shopActions.getUserRequest())
            await axios.get(`${baseUrl}/user/users-profile/${id}`).then(res => {
                dispatch(shopActions.getUserSuccess(res.data.data));
            })
        }
    },

    // To get a user's account balance
    get_account_balance: (account_no) => {
        return async dispatch => {
            dispatch(shopActions.getAccountBalanceRequest());
            await axios.get(`${baseUrl}/accounts/${account_no}/balance`)
                .then((res) => {
                    dispatch(shopActions.getAccountBalanceSuccess(res.data.data));
                });
        }
    },

}
export default auth;