import { shopConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    types: [],
    properties: [],
    categories: []
};

function productReq(state = initialState, action) {
    switch (action.type) {
        case shopConstants.PRODUCT_REQ_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case shopConstants.PRODUCT_TYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                types: action.payload,
            };
        case shopConstants.PRODUCT_PROPERTIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                properties: action.payload,
            };
        case shopConstants.PRODUCT_CATEGORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                categories: action.payload,
            };
        default:
            return state
    }
}

export default productReq;