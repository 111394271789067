import { Button, Input, Form, Col, Row } from 'antd';
import React, { useState } from 'react';
import Navigation from '../../components/Navigation';
import { connect } from 'react-redux';
import { HeaderBar } from '../../Apperance';
import invoice from '../../services/invoiceService';
import DynamicField from '../../components/Invoice/DynamicField';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};


function GenerateInvoice(props) {
    const [form] = Form.useForm();

    const [invoice, setInvoice] = useState({

    })

    const onFinish = (values) => {
        const invoice = {
            sender: {
                company: values.company,
                address: values.address,
                zip: values.zip,
                city: values.city,
                country: values.country,
                userId: 2
            },
            client: {
                company: values.company2,
                address: values.address2,
                zip: values.zip2,
                city: values.city2,
                country: values.country2
            },
            items: values.items
        }
        console.log(invoice)
        props.generate_invoice(invoice)
    };

    return (
        <Navigation {...props}>
            <HeaderBar topic="Generate Invoice" />
            <div className='create-store-page'>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Form
                        {...formItemLayout}
                        form={form}
                        layout='vertical'
                        name="register"
                        onFinish={onFinish}
                        scrollToFirstError >
                        <Row>
                            <Col lg={4}></Col>
                            <Col span={24} lg={8} sm={24} xs={24} style={{ padding: '8px' }}>
                                <h4 style={{ fontWeight: "bolder", textAlign: 'center' }}>FROM</h4>
                                <div className='create-store-box' >
                                    <Form.Item
                                        style={{ padding: '0px', margin: '0px' }}
                                        name="company"
                                        label='Company'
                                        rules={[{ required: true, message: 'Please enter your company name' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        name="address"
                                        style={{ padding: '0px', margin: '0px' }}
                                        label='Addresss'
                                        rules={[{ required: true, message: 'Please enter your address' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        label='Zip Code'
                                        name="zip"
                                        style={{ padding: '0px', margin: '0px' }}
                                        rules={[{ required: true, message: 'zip code' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        label='City'
                                        name="city"
                                        style={{ padding: '0px', margin: '0px' }}
                                        rules={[{ required: true, message: 'Please enter your city' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        label='Country'
                                        name="country"
                                        style={{ padding: '0px', margin: '0px' }}
                                        rules={[{ required: true, message: 'Please enter your country' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>

                                </div>
                            </Col>
                            <Col span={24} lg={8} sm={24} xs={24} style={{ padding: '8px' }}>
                                <h4 style={{ fontWeight: "bolder", textAlign: 'center' }}>TO</h4>
                                <div className='create-store-box'>

                                    <Form.Item
                                        style={{ padding: '0px', margin: '0px' }}
                                        name="company2"
                                        label='Company'
                                        rules={[{ required: true, message: 'Please enter your company name' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        style={{ padding: '0px', margin: '0px' }}
                                        name="address2"
                                        label='Addesss'
                                        rules={[{ required: true, message: 'Please enter your address' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        style={{ padding: '0px', margin: '0px' }}
                                        label='Zip Code'
                                        name="zip2"
                                        rules={[{ required: true, message: 'zip code' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        style={{ padding: '0px', margin: '0px' }}
                                        label='City'
                                        name="city2"
                                        rules={[{ required: true, message: 'Please enter your city' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>


                                    <Form.Item
                                        style={{ padding: '0px', margin: '0px' }}
                                        label='Country'
                                        name="country2"
                                        rules={[{ required: true, message: 'Please enter your country' }]}>
                                        <Input style={{ padding: '0px', margin: '0px' }} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col lg={4}></Col>
                            <Col span={24} lg={24} sm={24} xs={24} style={{ padding: '8px' }}>
                                <h4 style={{ fontWeight: "bolder", textAlign: 'center' }}>ITEMS</h4>
                                <div className='create-store-box'>
                                    <DynamicField />
                                </div>


                            </Col>
                        </Row>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit" onClick={onFinish}>
                                Generate Invoice
                                </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Navigation>
    )
}

const mapStateToProps = (state) => {
    return {
        invoice: state.generateInvoice.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        generate_invoice: (data) => dispatch(invoice.generate_invoice(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateInvoice)